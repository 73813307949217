@import "../../variables";

.request-to-join-page {
  &.cover-bg-container {
    background-image: url("/img/tg-background-gradient.svg");
  }


  .panel-centered {
    padding: 28px 49px 27px!important;

    .panel-centered-logo {
      margin-bottom: 29px;
    }

    &.form-style-labels-inside-inputs .form-group {
      margin: 6px 0;
      display: flex;
      flex-wrap: wrap;
      background-color: transparent;

      &.has-error {
        margin-bottom: 6px !important;
      }

      label {
        display: block;
        padding: 12px 0 12px 16px;
        flex-basis: 50%;
        line-height: 22px;
        background-color: $white;
        margin-bottom: 0px;
      }

      .form-control {
        display: block;
        padding: 12px 16px 12px 0;
        flex-basis: 50%;
        background-color: $white;
      }

      .help-block {
        position: relative !important;
        margin-bottom: 0 !important;
        display: block;
        flex-basis: 100%;
        min-width: 100%;
        flex-grow: 1;
      }
    }

    p {
      margin: 10px 0;
      @include font-normal();
      font-size: $font-size-text6;
      color: $lighter-black;

      @media (max-width:1280px) {
        font-size: $font-size-text5;
      }

      @media (max-width:1024px) {
        font-size: $font-size-text4;
      }

      @media (max-width:880px) {
        font-size: $font-size-text2;
      }
    }

    .panel-centered-actions {
      margin-top: 25px;
      text-align: center;

      .btn-primary{
        margin-right: 5px;
      }
    }
  }

}
