// @import "~bootstrap-sass/assets/stylesheets/bootstrap/_variables";
// @import "~bootstrap-sass/assets/stylesheets/bootstrap/_mixins";
// @import "~bootstrap-sass/assets/stylesheets/bootstrap/_grid";

@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/grid";

@import "variables";
@import "mixins";

//Widths
$col-1: percentage((1 / $grid-columns));
$col-2: percentage((2 / $grid-columns));
$col-3: percentage((3 / $grid-columns));
$col-4: percentage((4 / $grid-columns));
$col-5: percentage((5 / $grid-columns));
$col-6: percentage((6 / $grid-columns));
$col-7: percentage((7 / $grid-columns));
$col-8: percentage((8 / $grid-columns));
$col-9: percentage((9 / $grid-columns));
$col-10: percentage((10 / $grid-columns));
$col-11: percentage((11 / $grid-columns));
$col-12: percentage((12 / $grid-columns));

textarea {
  resize: none;
}

ul,
ol {
  list-style: none;
}

ul.comma-separated-list {
  display: block !important;
  margin: 0;
  padding: 0;

  li {
    display: inline;
    margin-right: 3px;

    &:after {
      content: ",";
    }

    &:last-child {
      margin-right: 0;

      &:after {
        content: "";
      }
    }
  }
}

strong {
  @include font-black();
}

a,
a:visited,
a:hover {
  font-size: $font-size-text5;
  color: $primary;
  text-decoration: none !important;
  outline: 0 !important;

  .glyphicon {
    font-size: $font-size-text3;
  }

  .icon {
    margin-right: 3px;
  }

  @media (max-width: 1280px) {
    font-size: $font-size-text4;

    .glyphicon {
      font-size: $font-size-text2;
    }
  }

  @media (max-width: 1024px) {
    font-size: $font-size-text3;

    .glyphicon {
      font-size: $font-size-text1;
    }
  }

  @media (max-width: 880px) {
    font-size: $font-size-text1;

    .glyphicon {
      font-size: $font-size-text0;
    }
  }

  &.no-click {
    cursor: default;
  }

  &.btn {
    &.btn-default {
      color: $very-dark-grey !important;
      background-color: $grey !important;

      &:hover {
        background-color: $lighter-grey !important;
      }
    }

    &.btn-primary {
      color: $white;
      background-color: $primary;

      &:hover {
        color: $white;
        background-color: $tg-light-blue;
      }
    }

    &.btn-danger {
      background-color: $red !important;

      &:hover {
        background-color: $light-red !important;
      }
    }
  }
}

.text-emphasise {
  @include font-medium();
  color: $lighter-black;
}

.text-justify-full {
  text-align: justify;

  &:after {
    content: "";
    display: inline-block;
    width: 100%;
  }
}

.clickable {
  cursor: pointer;
}

.inline {
  display: inline;
}

// required by angular-ui
.nav,
.pagination,
.carousel,
.panel-title a {
  cursor: pointer;
}

/*Layout------------------------------------------------------------------------------------------------------------------------------------------------------*/
.layout-inline-block {
  display: inline-block;
}

.layout-table {
  display: table;

  &.layout-table-fixed {
    table-layout: fixed;
  }

  .layout-table-cell {
    @include layout-table-cell();
  }
}

/*Position----------------------------------------------------------------------------------------------------------------------------------------------------*/
.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute;
}

/*Colors------------------------------------------------------------------------------------------------------------------------------------------------------*/
.color-white,
a.color-white {
  color: $white !important;
}

.color-very-light-grey,
a.color-very-light-grey {
  color: $light-grey !important;
}

.color-light-grey,
a.color-light-grey {
  color: $light-grey !important;
}

.color-grey,
a.color-grey {
  color: $grey !important;
}

.color-dark-grey,
a.color-dark-grey {
  color: $dark-grey !important;
}

.color-very-dark-grey,
a.color-very-dark-grey {
  color: $very-dark-grey !important;
}

.color-light-green,
a.color-light-green {
  color: $primary;
}

.color-green,
a.color-green {
  color: $lighter-black !important;
}

.color-light-blue,
a.color-light-blue {
  color: $lighter-grey !important;
}

.color-blue,
a.color-blue {
  color: $blue !important;
}

.color-dark-blue,
a.color-dark-blue {
  color: $dark-blue !important;
}

.color-red,
a.color-red {
  color: $red !important;
}

.color-marker {
  width: 13px;
  height: 13px;
  border-radius: 7px;
}

/*Line heights------------------------------------------------------------------------------------------------------------------------------------------------*/
.line-height-28 {
  line-height: 28px;
}

/*Action links------------------------------------------------------------------------------------------------------------------------------------------------*/

a.action-link {
  padding: 6px 12px 4px;
  font-size: $font-size-text5;
  line-height: 1;
  color: $primary;
  cursor: pointer;
  background: transparent no-repeat 10px 5px;
  white-space: nowrap;

  @media (max-width: 1280px) {
    font-size: $font-size-text4;
  }

  @media (max-width: 1024px) {
    font-size: $font-size-text3;
    background-size: auto 13px;
  }

  @media (max-width: 880px) {
    font-size: $font-size-text1;
    background-size: auto 12px;
  }

  &:hover {
    border-radius: 20px;
    background-color: $light-grey;
  }

  &.action-link-disabled {
    color: $darker-grey !important;
    cursor: default;

    &:hover {
      background-color: transparent;
    }
  }
}

/*Colors------------------------------------------------------------------------------------------------------------------------------------------------------*/

.color-white {
  color: $white;
}

.color-text-grey {
  color: $very-dark-grey;
}

.color-radio-grey {
  color: $dark-grey;
}

.color-portal-light-grey {
  color: $light-grey;
}

.color-portal-dark-grey {
  color: $dark-grey;
}

.color-grey-lines {
  color: $grey;
}

.color-light-green {
  color: $primary;
}

.color-dark-green {
  color: $lighter-black;
}

.color-light-blue {
  color: $lighter-blue;
}

.color-medium-blue {
  color: $blue;
}

.color-dark-blue {
  color: $dark-blue;
}

.color-blue-lines {
  color: $light-blue;
}

/*Icons-------------------------------------------------------------------------------------------------------------------------------------------------------*/

@include icon-size(3, $font-size-text3, $font-size-text2, $font-size-text1, $font-size-text1);
@include icon-size(4, $font-size-text4, $font-size-text3, $font-size-text2, $font-size-text1);
@include icon-size(5, $font-size-text5, $font-size-text4, $font-size-text3, $font-size-text1);
@include icon-size(6, $font-size-text6, $font-size-text5, $font-size-text4, $font-size-text2);
@include icon-size(7, $font-size-text7, $font-size-text6, $font-size-text5, $font-size-text3);
@include icon-size(8, $font-size-text8, $font-size-text7, $font-size-text6, $font-size-text4);

.icon-size-8 {
  vertical-align: -1px;
}

@include icon-size(9, $font-size-text9, $font-size-text8, $font-size-text7, $font-size-text5);

.icon-size-9 {
  display: inline-block;
  padding-top: 2px;
  vertical-align: -2px;

  &.ion-close-circled {
    vertical-align: -3px;
  }
}

@include icon-size(10, $font-size-text10, $font-size-text9, $font-size-text8, $font-size-text6);

.icon-size-10 {
  vertical-align: -3px;

  &.ion-close-circled {
    vertical-align: -3px;
  }
}

@include icon-size(11, $font-size-text11, $font-size-text10, $font-size-text9, $font-size-text7);

.icon-size-11 {
  vertical-align: -3px;
}

@include icon-size(12, $font-size-text12, $font-size-text11, $font-size-text10, $font-size-text8);
.icon-size-12 {
  vertical-align: -3px;
}

/*Inputs------------------------------------------------------------------------------------------------------------------------------------------------------*/
.radio {
  width: 100%;
  margin-top: 0;
  margin-bottom: 5px;
  padding: 10px 8px 8px 8px;
  font-size: $font-size-text5;
  color: $very-dark-grey;
  text-transform: uppercase;
  background-color: $lighter-grey;

  &.radio-inline {
    display: inline-block;
    width: auto;
    margin-right: 5px !important;

    label {
      width: auto;

      & > span {
        &:first-of-type {
          width: 20px;
        }

        & > span {
          margin-right: 5px;
        }
      }
    }
  }

  @media (max-width: 1280px) {
    font-size: $font-size-text4;
  }
  @media (max-width: 1024px) {
    font-size: $font-size-text3;
  }

  @media (max-width: 880px) {
    font-size: $font-size-text1;
  }

  label {
    display: table;
    width: 100%;
    padding-left: 0;
    line-height: 1.5em;

    & > span {
      display: table-cell;
      vertical-align: middle;

      &:first-of-type {
        width: 25px;
      }

      & > span {
        display: inline-block;
        width: 14px;
        height: 14px;
        margin: 2px 15px 3px 3px;
        border-radius: 7px;
        border: 1px solid $primary;
        vertical-align: bottom;
      }
    }

    input[type="radio"]:not(old) {
      display: none;

      &:checked ~ span > span {
        border: 1px solid $primary;

        & > span {
          display: block;
          width: 6px;
          height: 6px;
          margin: 3px;
          border: 2px solid $primary;
          border-radius: 6px;
          background: $primary;
        }
      }
    }
  }
}

.checkbox {
  width: 100%;
  margin-top: 0;
  margin-bottom: 5px;
  font-size: $font-size-text5;
  color: $very-dark-grey;

  @media (max-width: 1280px) {
    font-size: $font-size-text3;
  }

  @media (max-width: 1024px) {
    font-size: $font-size-text2;
  }

  @media (max-width: 880px) {
    font-size: $font-size-text0;
  }

  input[type="checkbox"]:not(old) {
    width: 0;
    margin: 0;
    padding: 0;
    font-size: 1em;
    opacity: 0;

    & + label {
      display: inline;
      padding-left: 0;
      line-height: 1.5em;
      min-height: 10px;

      & > span {
        display: inline-block;
        width: 1.2em;
        height: 1.2em;
        margin: 0.25em 1.2em 0.25em 2px;
        border-radius: 0.25em;
        border: 1px solid $primary;
        vertical-align: bottom;
        background-color: $white;
      }
    }

    &:checked + label > span {
      border: 1px solid $primary;

      &:before {
        content: "\2713";
        display: block;
        width: 1.2em;
        color: $primary;
        font-size: 0.875em;
        line-height: 1.2em;
        text-align: center;
        font-weight: bold;
      }
    }
  }

  &.no-label {
    margin: -7px 0 0;
    padding: 0;

    input[type="checkbox"]:not(old) {
      display: none;

      & + label {
        margin-left: 0;

        & > span {
          margin: 0;
        }
      }
    }
  }

  &.checkbox-gray input[type="checkbox"]:not(old) {
    & + label > span {
      border-color: $grey;
    }

    &:checked + label > span {
      border-color: $grey;

      &:before {
        color: $very-dark-grey;
      }
    }
  }

  &.checkbox-inline {
    display: inline;
  }

  &.nowrap {
    input[type="checkbox"]:not(old) {
      & + label {
        white-space: nowrap;
      }
    }
  }
}

table .checkbox label {
  padding-left: 0;
}

.dropdown {
  .dropdown-arrow {
    display: inline-block;
    width: 9px;
    height: 8px;
    margin-top: -4px;
    margin-left: 4px;
    background: url("../img/select-arrow-small-green.png");
  }
}

.bootstrap-switch {
  border-color: $dark-grey;
  border-radius: 30px;
  -webkit-box-shadow: none;
  box-shadow: none;

  .bootstrap-switch-label {
    background: transparent url("../img/dot-gray.png") center center no-repeat;
  }

  .bootstrap-switch-handle-on,
  .bootstrap-switch-handle-off {
    font-size: $font-size-text2;

    @media (max-width: 1024px) {
      font-size: $font-size-text1;
    }

    @media (max-width: 880px) {
      font-size: $font-size-text0;
    }
  }

  .bootstrap-switch-handle-on.bootstrap-switch-primary,
  .bootstrap-switch-handle-off.bootstrap-switch-default {
    color: $dark-grey;
    background-color: transparent;
  }
}

/*Form styles-------------------------------------------------------------------------------------------------------------------------------------------------*/

.form-uppercase {
  label {
    text-transform: uppercase;
  }
}

.form-30-70 {
  .form-group label {
    width: 30%;
  }
}

.form-40-60 {
  .form-group label {
    width: 40% !important;
  }
}

.form-50-50 {
  .form-group label {
    width: 50%;
  }
}

.form-60-40 {
  .form-group label {
    width: 60%;
  }
}

.form-70-30 {
  .form-group label {
    width: 70%;
  }
}

.form-condensed {
  .form-group {
    margin: 6px 0 !important;

    label {
      padding: 10px 7px 8px 15px !important;
    }

    input.form-control,
    select.form-control,
    textarea.form-control {
      padding: 10px 15px 8px 0;
    }
  }
}

.form-style-labels-inside-inputs {
  .form-group {
    display: table;
    position: relative;
    width: 100%;
    margin: 14px 0;
    background-color: $white;

    &.has-error {
      margin-bottom: 30px !important;
    }

    label {
      display: table-cell;
      width: 50%;
      padding: 16px 0 16px 26px;
      font-size: 14px;
      line-height: 18px;
      color: $lighter-black;
      text-align: left;
      vertical-align: middle;
      border: 0;
      border-radius: 0;

      @media (max-width: 1280px) {
        font-size: $font-size-text3;
      }

      @media (max-width: 1024px) {
        font-size: $font-size-text2;
      }

      @media (max-width: 880px) {
        font-size: $font-size-text0;
      }
    }

    .control-label {
      vertical-align: top;
    }

    .form-control {
      display: table-cell;
      width: 100%;
      margin-bottom: 0;
      padding: 16px 26px 16px 0;
      font-size: $font-size-text5;
      line-height: 18px;
      color: $dark-blue;
      background-color: $white;

      @media (max-width: 1280px) {
        font-size: $font-size-text3;
      }

      @media (max-width: 1024px) {
        font-size: $font-size-text2;
      }

      @media (max-width: 880px) {
        font-size: $font-size-text0;
      }

      &:focus {
        border-left: none;

        & ~ .focus-highlight {
          visibility: visible;
        }
      }
    }

    select.form-control {
      padding-right: 30px;
      -webkit-appearance: none; /*Removes default chrome and safari style*/
      -moz-appearance: none; /* Removes Default Firefox style*/
      background: transparent url("../img/select-arrow-green.png") no-repeat; /*Adds background-image*/
      background-position: right 9px center; /*Position of the background-image*/
      text-indent: 0.01px; /* Removes default arrow from firefox*/
      text-overflow: ""; /*Removes default arrow from firefox*/

      /*Removes default arrow from IE*/
      &::-ms-expand {
        display: none;
      }

      &.select-multi-line {
        background: none;
      }
    }

    .help-block {
      position: absolute;
      bottom: 0;
      left: 0;
      margin-bottom: -35px;
    }

    .focus-highlight {
      visibility: hidden;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 5px;
      background-color: $primary;
    }

    &.has-error .focus-highlight {
      visibility: hidden !important;
    }
  }
}

.form-style-labels-inside-inputs-condensed {
  @extend .form-style-labels-inside-inputs;

  .form-group {
    margin-top: 0;
    margin-bottom: 5px;

    label {
      width: 1px; // make label table column as compact as possible
      padding: 8px 0 8px 10px;
    }
  }
}

.form-style-labels-inside-inputs-gray {
  @extend .form-style-labels-inside-inputs;

  .form-group {
    background-color: $light-grey;

    label {
      color: $very-dark-grey;
    }

    .form-control {
      color: $very-dark-grey;
      background-color: $light-grey;
    }
  }
}

.form-style-labels-inside-inputs-dark {
  @extend .form-style-labels-inside-inputs;

  .form-group {
    background-color: $dark-grey;

    label {
      color: $lighter-grey;
    }

    .form-control {
      color: $lighter-grey;
      background-color: $dark-grey;
    }
  }
}

.form-style-bordered-inputs {
  .form-group {
    margin-bottom: 8px;

    &.form-group-sm {
      .form-control,
      &.no-background select.form-control {
        @include form-style-bordered-inputs-form-control();
      }

      select.form-control {
        min-height: 28px;
      }
    }

    &.form-group-multiple-inputs {
      label {
        display: block;
      }

      .form-control {
        display: inline-block;
      }

      .checkbox {
        display: inline-block;
        width: inherit;

        &.layout-table-cell {
          @include layout-table-cell();
        }
      }

      .bootstrap-switch {
        display: inline-block;
        margin-top: 0;
      }
    }

    .form-control,
    &.no-background select.form-control {
      margin-top: 0;
      margin-bottom: 8px !important;
      padding: 9px !important;
      color: $very-dark-grey;
      border: 1px solid $grey;
      border-radius: 3px;
      background-color: $white;
      height: 40px;
      line-height: 22px;

      &:focus {
        padding: 9px !important;
        border: 1px solid $grey !important;
      }
    }

    .checkbox {
      margin-top: 3px;

      input[type="checkbox"]:not(old) + label > span {
        margin-left: 0;
      }
    }

    .input-with-checkbox {
      .form-control {
        display: inline-block;
        margin-bottom: 0 !important;
      }

      .checkbox {
        display: inline-block;
        width: auto !important;
      }
    }

    .bootstrap-switch {
      display: block;
      margin-top: 3px;
    }
  }

  select {
    margin: 7px 0 10px;
    padding: 9px !important;
    padding-right: 25px !important;
    color: $very-dark-grey;
    border: 1px solid $grey;
    border-radius: 3px;
    -webkit-appearance: none; /*Removes default chrome and safari style*/
    -moz-appearance: none; /* Removes Default Firefox style*/
    background: transparent url("../img/select-arrow-gray.png") no-repeat; /*Adds background-image*/
    background-position: right 9px center; /*Position of the background-image*/
    text-indent: 0.01px; /* Removes default arrow from firefox*/
    text-overflow: ""; /*Removes default arrow from firefox*/
    background-color: $white;

    &[multiple] {
      background: none;
    }

    &:focus {
      padding: 9px !important;
      padding-right: 25px !important;
      border: 1px solid $grey !important;
      outline: none;
    }

    /*Removes default arrow from IE*/
    &::-ms-expand {
      display: none;
    }
  }

  .ui-select {
    &.form-control {
      padding: 0 !important;
      border: 0 !important;
    }

    .ui-select-match > .btn,
    .ui-select-match > .btn:hover,
    .ui-select-match > .btn-default,
    .ui-select-match > .btn-default:hover {
      margin-bottom: 0 !important;
      padding: 9px !important;
      padding-right: 25px !important;
      line-height: 1.42857143;
      color: $very-dark-grey;
      border: 1px solid $grey;
      border-radius: 3px;
      background-color: transparent;
    }

    .ui-select-bootstrap .ui-select-toggle > .caret {
      position: absolute;
      height: 10px;
      top: 50%;
      right: 10px;
      margin-top: -2px;
    }
  }

  .sp-replacer {
    padding: 4px 20px 7px 4px;
    border: none;
    background: $white url("../img/select-arrow-gray.png") no-repeat;
    background-position: right 5px top 9px;

    .sp-preview {
      border: 1px solid $grey;

      &,
      .sp-preview-inner {
        width: 13px;
        height: 13px;
        border-radius: 7px;
      }
    }

    .sp-dd {
      display: none;
    }
  }

  .checkbox {
    &.checkbox-inline {
      padding-left: 2px;
      font-size: $font-size-text5;

      @media (max-width: 1280px) {
        font-size: $font-size-text4;
      }

      @media (max-width: 1024px) {
        font-size: $font-size-text3;
      }

      @media (max-width: 880px) {
        font-size: $font-size-text1;
      }

      input[type="checkbox"]:not(old) + label > span {
        margin-right: 10px;
      }
    }

    label {
      line-height: 20px !important;

      & > span {
        font-size: $font-size-text4 !important;
      }
    }
  }

  .radio {
    margin: 6px 0;
    padding: 0;
    text-transform: none;
    background-color: transparent;

    &.radio-inline {
      margin: 12px 0;
    }

    label {
      & > span {
        line-height: 1.3em;

        & > span {
          margin-left: 0;
          border-color: $dark-grey;
        }
      }

      input[type="radio"]:not(old) {
        &:checked ~ span > span {
          border-color: $dark-grey;

          & > span {
            border-color: $dark-grey;
            background: $dark-grey;
          }
        }
      }
    }

    select {
      margin: 0;
      padding: 4px !important;
      padding-right: 25px !important;
      background-color: $light-grey;
    }
  }

  .time-selector .form-group {
    margin-bottom: 5px;

    label.control-label {
      vertical-align: middle;
      color: $very-dark-grey;
    }

    .form-control {
      min-width: 40px;
      margin-bottom: 0 !important;
    }
  }
}

.form-group.no-background {
  background-color: transparent;

  label,
  .form-control {
    @include font-normal();
    font-size: $font-size-text5;
    color: $very-dark-grey;
    background-color: transparent;

    @media (max-width: 1280px) {
      font-size: $font-size-text4;
    }

    @media (max-width: 1024px) {
      font-size: $font-size-text3;
    }

    @media (max-width: 880px) {
      font-size: $font-size-text1;
    }
  }

  select.form-control {
    margin: 7px 0 10px;
    padding: 5px 25px 5px 5px;
    -webkit-appearance: none; /*Removes default chrome and safari style*/
    -moz-appearance: none; /* Removes Default Firefox style*/
    background: transparent url("../img/select-arrow-gray.png") no-repeat; /*Adds background-image*/
    background-position: right 9px center; /*Position of the background-image*/
    text-indent: 0.01px; /* Removes default arrow from firefox*/
    text-overflow: ""; /*Removes default arrow from firefox*/

    /*Removes default arrow from IE*/
    &::-ms-expand {
      display: none;
    }
  }
}

.form-group.form-collection {
  .control-label {
    position: relative;
    width: 100% !important;

    a.collection-add-button {
      margin-left: 10px;
    }
  }

  .form-collection-row {
    position: relative;

    .form-group {
      margin: 0 !important;
    }

    .collection-remove-button {
      position: absolute;
      top: 10px;
      right: 3px;
      padding-right: 7px !important;
      text-align: right;
    }
  }

  .help-block {
    position: relative;
    margin-bottom: 0;
  }
}

.form-group.form-multiselection {
  ul.selected-items {
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      margin: 0 4px 7px 0;
      padding: 2px 8px 1px 12px;
      border: 1px solid $grey;
      border-radius: 10px;
    }
  }
}

/*Margin------------------------------------------------------------------------------------------------------------------------------------------------------*/
.margin-top-0 {
  margin-top: 0 !important;
}

.margin-top-5 {
  margin-top: 5px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-top-25 {
  margin-top: 25px !important;
}

.margin-right-20 {
  margin-right: 20px !important;
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.margin-bottom-5 {
  margin-bottom: 5px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-bottom-25 {
  margin-bottom: 25px !important;
}

.margin-left-5 {
  margin-left: 5px !important;
}

.margin-left-10 {
  margin-left: 10px !important;
}

/*Padding-----------------------------------------------------------------------------------------------------------------------------------------------------*/
.padding-horizontal-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.padding-vertical-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.padding-top-5 {
  padding-top: 5px !important;
}

.padding-top-8 {
  padding-top: 8px !important;
}

.padding-right-0 {
  padding-right: 0 !important;
}

.padding-right-5 {
  padding-right: 5px !important;
}

.padding-right-7 {
  padding-right: 7px !important;
}

.padding-right-10 {
  padding-right: 10px !important;
}

.padding-bottom-0 {
  padding-bottom: 0 !important;
}

.padding-left-0 {
  padding-left: 0 !important;
}

.padding-left-7 {
  padding-left: 7px !important;
}

.padding-left-20 {
  padding-left: 20px !important;
}

/*Columns-----------------------------------------------------------------------------------------------------------------------------------------------------*/

.col-3 {
  width: 3% !important;
}

.col-5 {
  width: 5% !important;
}

.col-10 {
  width: 10% !important;
}

.col-15 {
  width: 15% !important;
}

.col-20 {
  width: 20% !important;
}

.col-25 {
  width: 25% !important;
}

.col-30 {
  width: 30% !important;
}

.col-33 {
  width: 33% !important;
}

.col-35 {
  width: 35% !important;
}

.col-40 {
  width: 40% !important;
}

.col-50 {
  width: 50% !important;
}

.col-60 {
  width: 60%;
}

.col-100 {
  width: 100% !important;
}

.col-px-20 {
  min-width: 20px !important;
  width: 20px !important;
}

.col-px-50 {
  min-width: 50px !important;
  width: 50px !important;
}

.col-px-55 {
  min-width: 55px !important;
  width: 55px !important;
}

.col-px-75 {
  min-width: 75px !important;
  width: 75px !important;
}

.col-px-110 {
  min-width: 110px !important;
  width: 110px !important;
}

.col-px-140 {
  min-width: 140px !important;
  width: 140px !important;
}

.col-px-190 {
  min-width: 190px !important;
  width: 190px !important;
}

.col-px-220 {
  min-width: 220px !important;
  width: 220px !important;
}

.col-actions {
  white-space: nowrap;

  a {
    margin-right: 15px;

    img {
      margin-top: -4px;
      margin-right: 3px;
    }
  }
}

.col-min-width-355 {
  min-width: 355px;
}

.col-min-width-550 {
  min-width: 550px;
}

.col-min-width-600 {
  min-width: 600px;
}

.col-min-width-800 {
  min-width: 800px;
}

.col-min-width-1000 {
  min-width: 1000px;
}

.col-min-width-1200 {
  min-width: 1200px;
}

/*Tables------------------------------------------------------------------------------------------------------------------------------------------------------*/

.table {
  table-layout: fixed;
  border-collapse: collapse !important;

  .sort {
    display: inline-block;
    width: 9px;
    height: 8px;
    margin-left: 5px;
    background-repeat: no-repeat;

    &.sort-asc {
      background-image: url("../img/sort-asc.png");
    }

    &.sort-desc {
      background-image: url("../img/sort-desc.png");
    }
  }

  tr a {
    @media (max-width: 1280px) {
      font-size: $font-size-text4;
    }

    @media (max-width: 1024px) {
      font-size: $font-size-text3;
    }

    @media (max-width: 880px) {
      font-size: $font-size-text1;
    }
  }

  &.table-expandable {
    tbody tr {
      &:not(.row-expansion) td {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &.selected:not(.row-expansion) td {
        border: 0 solid $grey;
        background-color: $light-grey;

        &:first-child {
          border-bottom-width: 1px;
        }
      }

      &:not(.selected) + tr.row-expansion td {
        border: none transparent;
        height: 0;
      }

      &.row-expansion {
        td {
          padding: 0 !important;
          border: 0 solid $grey;
          border-bottom-width: 1px;
          border-top-width: 1px;
          background-color: $light-grey;
          height: auto;
          transition: padding 0.3s linear;

          &:first-child {
            border-right-width: 1px;
            border-top-width: 0;
            background-color: $white;
            padding: 0 !important;
          }

          &:last-child {
            border-right-width: 1px;
          }
        }

        .row-expansion-body {
          padding: 0;
          position: relative;
          max-height: 0;
          white-space: normal;
          transition: all 0.3s ease-out;

          .form-group {
            display: inline-block;
            margin-right: 20px;
            margin-bottom: 0;
            vertical-align: top;

            .control-label {
              margin-bottom: 0;
              font-weight: bold;
              vertical-align: top;
              margin-right: 3px;
              display: inherit;
            }

            .form-control-static {
              display: inline-block;
              margin-top: 0;
              padding-top: 0;
            }

            ul {
              &.comma-separated-list {
                display: inline-block !important;
                margin-top: 0;
                padding-top: 0;
              }
            }
          }
        }

        &.expanded .row-expansion-body {
          padding: 10px;
          max-height: 200px;
          overflow: auto;
          transition: all 0.3s ease-in;
        }
      }
    }
  }

  thead,
  tbody {
    tr {
      td,
      th {
        &.col-checkbox {
          width: 30px !important;
          padding: 0 !important;
          vertical-align: middle;
          text-align: center;
        }

        &.col-id {
          min-width: 50px !important;
          width: 70px;
          max-width: 5%;
          vertical-align: middle;
          text-align: left;
        }

        &.col-status {
          width: 100px !important;
          vertical-align: middle;
          text-align: center;
          white-space: normal;
        }
      }
    }
  }

  > thead > tr > th {
    vertical-align: middle;
  }
}

.actions-header-bar {
  display: table;
  width: 100%;
  padding: 11px 12px 13px;
  background-color: $light-grey;

  input {
    padding: 6px 11px;
    border: 1px solid $grey;
    border-radius: 3px;

    &:focus {
      outline: 0;
      box-shadow: 0 1px 6px -2px $dark-purple;
    }

    &::-webkit-input-placeholder {
      /* WebKit browsers */
      color: $very-dark-grey;
    }

    &:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: $very-dark-grey;
      opacity: 1;
    }

    &::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: $very-dark-grey;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: $very-dark-grey;
    }

    &.input-search {
      min-width: 320px;
    }
  }
}

@mixin actions-bar-footer-font-size() {
  font-size: $font-size-text5;

  @media (max-width: 1024px) {
    font-size: $font-size-text2;
  }

  @media (max-width: 880px) {
    font-size: $font-size-text0;
  }
}

.actions-footer-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 10px 0;
  padding: 0 3px;

  @include actions-bar-footer-font-size;

  a {
    margin-right: 8px;
    @include actions-bar-footer-font-size;

    img {
      height: 13px;
      margin-top: -4px;
      margin-right: 7px;
    }
  }

  .form-group {
    &.no-background {
      .form-control {
        @include actions-bar-footer-font-size;
      }
    }
  }

  .checkbox {
    margin-right: 20px;
    padding-left: 0;
    margin-top: 1px;
    width: auto;
    float: left;

    @include actions-bar-footer-font-size;

    & input[type="checkbox"]:not(old) + label {
      float: left;

      & > span {
        //Adjust the margin left with checkbox column of the table.
        margin-left: 4.5px;
        margin-right: 0.8em;

        font-size: $font-size-text4;
        @media (max-width: 1024px) {
          font-size: $font-size-text2;
        }

        @media (max-width: 880px) {
          font-size: $font-size-text0;
          margin-left: 0.8em;
        }
      }
    }
  }

  .btn-primary {
    padding: 7px 22px;
  }

  .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;

    select.form-control {
      width: auto;
      margin: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .table-page-selector {
    display: inline-block;
    margin-left: 28px;

    a {
      padding: 6px 12px;
      margin: 0 8px;

      &[disabled="disabled"] {
        color: $darker-grey !important;
        pointer-events: none;

        &:hover {
          background-color: transparent;
        }
      }

      &:hover {
        border-radius: 20px;
        background-color: $light-grey;
      }

      &:first-child {
        margin-right: 8px;
      }

      &:last-child {
        margin-left: 8px;
        margin-right: 0;
      }
    }
  }

  .pagination {
    margin: 0;

    & > li > a,
    & > li > span {
      margin-right: 0;
      padding: 3px 10px;
      border: none;
    }

    & > .active > a,
    & > .active > a:focus,
    & > .active > a:hover,
    & > .active > span,
    & > .active > span:focus,
    & > .active > span:hover {
      color: $white !important;
      cursor: pointer;
      background-color: $primary;
      border: none;
    }
  }
}

.actions-bar-panel-left,
.actions-bar-panel-right {
  display: flex;
  align-items: center;

  .checkbox {
    margin-bottom: 0;

    label {
      margin-bottom: 0;
    }
  }
}

/*Borders-----------------------------------------------------------------------------------------------------------------------------------------------------*/

.border-0 {
  border: none !important;
}

/*Centered panel with full page bg----------------------------------------------------------------------------------------------------------------------------*/

.cover-bg-container {
  width: 100%;
  min-height: 100%;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  display: flex;
  align-items: center;
  justify-content: center;

  .panel-centered-wrapper {

    .form-group {
      border: 1px solid #c9cace;
    }

    .panel-centered {
      width: 483px;
      min-height: 380px;
      //margin: calc(50vh - 190px) auto 0px;
      padding: 48px 49px 30px 49px;
      @include font-normal();
      font-size: $font-size-text4;
      background-color: $white;
      left: 0;
      right: 0;

      @media (max-width: 1280px) {
        font-size: $font-size-text3;
      }

      @media (max-width: 1024px) {
        font-size: $font-size-text2;
      }

      @media (max-width: 880px) {
        font-size: $font-size-text0;
      }

      .panel-centered-logo {
        display: block;
        margin: 0 auto 36px;
      }

      .panel-centered-header {
        margin-bottom: 27px;
        @include font-medium();
        font-size: $font-size-text5;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
        color: $lighter-black;

        @media (max-width: 1280px) {
          font-size: $font-size-text4;
        }

        @media (max-width: 1024px) {
          font-size: $font-size-text3;
        }

        @media (max-width: 880px) {
          font-size: $font-size-text1;
        }
      }
    }
  }
}

/*Page wrapper (needed for side bars)-------------------------------------------------------------------------------------------------------------------------*/

.page-wrapper {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;

  &.sidebar-right-toggled {
    .page-center-column {
      width: $col-7;
    }

    .sidebar-right {
      margin-right: 0;
      width: 100%;
    }
  }

  &.sidebar-left-toggled {
    .page-center-column {
      @include make-col(12);
      width: calc(#{$col-12} - 95px);
      margin-left: 45px;
    }

    .sidebar-left-expanded {
      margin-left: -14.99%;
      z-index: 1030;
      opacity: 0;
    }

    .sidebar-left-collapsed {
      opacity: 1;
      transition: opacity 0.5s ease;
      transition-delay: 0.5s;
      z-index: 1033;
    }
  }

  &.sidebar-left-panel-view-collapsed-toggled {
    .sidebar-left-expanded {
      background-color: $light-grey;
    }

    .page-center-column {
      width: calc(#{$col-10} - 45px);
      margin-left: calc(#{$col-2} + 45px);
    }

    .sidebar-left-panel-view-collapsed {
      visibility: visible;
      left: $col-2;
    }

    &.sidebar-left-toggled {
      .page-center-column {
        width: calc(#{$col-12} - 90px);
        margin-left: 90px;
      }

      .sidebar-left-panel-view-collapsed {
        left: 44px;
      }
    }

    &.sidebar-right-toggled .page-center-column {
      width: calc(#{$col-7} - 45px);
      margin-left: calc(#{$col-2} + 45px);
    }
  }

  &.sidebar-left-panel-view-expanded-toggled .sidebar-left-panel-view-expanded {
    visibility: visible;
  }

  &.sidebar-left-panel-subscribe-toggled .sidebar-left-panel-subscribe {
    visibility: visible;
  }

  &.sidebar-left-panel-save-toggled .sidebar-left-panel-save {
    visibility: visible;
  }

  &.sidebar-left-panel-view-expanded-toggled,
  &.sidebar-left-panel-subscribe-toggled,
  &.sidebar-left-panel-save-toggled {
    .sidebar-left-expanded {
      background-color: $light-grey;
    }

    .page-center-column {
      width: $col-8;
      margin-left: $col-4;
    }

    .sidebar-left-panel-view-expanded,
    .sidebar-left-panel-subscribe,
    .sidebar-left-panel-save {
      left: $col-2;
    }

    &.sidebar-left-toggled {
      .page-center-column {
        width: calc(#{$col-10} - 45px);
        margin-left: calc(#{$col-2} + 45px);
      }

      .sidebar-left-panel-view-expanded,
      .sidebar-left-panel-subscribe,
      .sidebar-left-panel-save {
        left: 44px;
      }
    }

    &.sidebar-right-toggled {
      .page-center-column {
        width: $col-5;
        margin-left: $col-4;
      }
    }
  }

  &.sidebar-left-panel-subscribe-toggled,
  &.sidebar-left-panel-save-toggled {
    .page-center-column {
      width: $col-8;
      margin-left: $col-4;
    }

    &.sidebar-left-panel-view-expanded-toggled {
      .page-center-column {
        width: $col-6;
        margin-left: $col-6;
      }

      .sidebar-left-panel-view-expanded {
        visibility: visible;
        left: $col-4;
      }

      &.sidebar-left-toggled {
        .page-center-column {
          width: calc(#{$col-8} - 45px);
          margin-left: calc(#{$col-4} + 45px);
        }

        .sidebar-left-panel-view-expanded {
          left: calc(#{$col-2} + 45px);
        }
      }

      &.sidebar-right-toggled {
        .page-center-column {
          width: $col-3;
          margin-left: $col-6;
        }
      }
    }

    &.sidebar-left-panel-view-collapsed-toggled {
      .page-center-column {
        width: calc(#{$col-8} - 45px);
        margin-left: calc(#{$col-4} + 45px);
      }

      .sidebar-left-panel-view-collapsed {
        visibility: visible;
        left: $col-4;
      }

      &.sidebar-left-toggled {
        .page-center-column {
          width: calc(#{$col-10} - 90px);
          margin-left: calc(#{$col-2} + 90px);
        }

        .sidebar-left-panel-view-collapsed {
          left: calc(#{$col-2} + 45px);
        }
      }

      &.sidebar-right-toggled {
        .page-center-column {
          width: calc(#{$col-5} - 45px);
          margin-left: calc(#{$col-4} + 45px);
        }
      }
    }
  }

  &.sidebar-right-toggled.sidebar-left-toggled {
    .page-center-column {
      width: calc(#{$col-9} - 45px);
      margin-left: 45px;
    }

    .sidebar-right {
      margin-right: 0;
    }

    .sidebar-left-expanded {
      margin-left: -($col-2);
    }

    .sidebar-left-collapsed {
      visibility: visible;
    }

    &.sidebar-left-panel-view-collapsed-toggled .page-center-column {
      width: calc(#{$col-9} - 90px);
      margin-left: 90px;
    }

    &.sidebar-left-panel-view-expanded-toggled,
    &.sidebar-left-panel-subscribe-toggled,
    &.sidebar-left-panel-save-toggled {
      .page-center-column {
        width: calc(#{$col-7} - 45px);
        margin-left: calc(#{$col-2} + 45px);
      }
    }

    &.sidebar-left-panel-subscribe-toggled,
    &.sidebar-left-panel-save-toggled {
      &.sidebar-left-panel-view-expanded-toggled {
        .page-center-column {
          width: $col-3;
        }

        &.sidebar-left-toggled {
          .page-center-column {
            width: calc(#{$col-5} - 45px);
            margin-left: calc(#{$col-4} + 45px);
          }
        }
      }

      &.sidebar-left-panel-view-collapsed-toggled {
        .page-center-column {
          width: $col-5;
        }

        &.sidebar-left-toggled {
          .page-center-column {
            width: calc(#{$col-7} - 90px);
            margin-left: calc(#{$col-2} + 90px);
          }
        }
      }
    }
  }

  .page-center-column.page-error {
    width: $col-12 !important;
    margin: 0 !important;

    div {
      width: $col-6;
      margin: 73px $col-3 0;

      h1 {
        margin-top: 45px;
        font-size: $font-size-text14;
      }

      p {
        font-size: $font-size-text8;
      }
    }
  }

  .dropdown-toggle {
    line-height: 24px;
  }
}

/*Top navigation bar------------------------------------------------------------------------------------------------------------------------------------------*/

.navbar {
  .nav-link {
    min-height: 37px;
    font-weight: 600;

    //To make sure icons doesn't jump when selection or hover is toggled
    border: 1px solid transparent;

    &:hover {
      background-color: $sidebar-hover-background-element !important;
      color: $white !important;
      border-radius: 4px;
    }

    &.nav-link-no-label {
      .nav-link-label {
        display: none;
      }

      i {
        &.icon {
          margin-right: 0;
        }
      }
    }

    .nav-link-admin-notifications {
      &::before {
        content: "\f3e2";
        position: relative;
        top: 2px;
        margin-left: 10px;
        display: inline-block;
        font-family: "Ionicons";
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        font-size: 18px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -moz-animation: ringing 2s 5 ease 1s;
        -webkit-animation: ringing 2s 5 ease 1s;
        -o-animation: ringing 2s 5 ease 1s;
        -ms-animation: ringing 2s 5 ease 1s;
        animation: ringing 2s 5 ease 1s;
        -moz-transform-origin: 50% 0;
        -webkit-transform-origin: 50% 0;
        -o-transform-origin: 50% 0;
        -ms-transform-origin: 50% 0;
        transform-origin: 50% 0;
      }
    }

    &.selected {
      border: 1px solid $primary;
      background-color: $lighter-grey;
    }

    .badge {
      position: relative;
      top: -2px;
    }
  }

  &.navbar-mobile {
    z-index: 1035;

    &.navbar-fixed-top {
      position: absolute;
      margin-bottom: 0;
    }

    .nav-link {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;

      -ms-flex-align: center;
      -webkit-align-items: center;
      -webkit-box-align: center;
      align-items: center;

      padding-top: 7px;

      i {
        &.icon {
          margin-right: 5px;
        }
      }

      &.nav-link-no-label {
        .nav-link-label {
          display: inline;
        }
      }
    }
  }

  .btn-group {
    .btn-primary {
      color: $primary;
      background: transparent;

      &:hover {
        background-color: $lighter-grey;
      }
    }
  }

  .bootstrap-switch-group {
    margin: 6px 0 0 15px;
    font-size: 14px;
  }
}

.navbar-mobile-fake-bar {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
  min-height: 64px;
  border-bottom: 1px solid $grey;
  background-color: $light-grey;
}

/*Top navigation bar related styles---------------------------------------------------------------------------------------------------------------------------*/

.top-nav-page {
  min-height: 100%;
}

/*Global alert messages---------------------------------------------------------------------------------------------------------------------------------------*/

.alerts-global {
  position: absolute;
  top: 65px;
  left: calc(50% - 200px);
  width: 400px;
  margin-bottom: 0;
  padding: 0;
  z-index: 1024;

  .alert {
    display: inline-block;
    width: 400px;
    margin-bottom: 5px;
    text-align: center;

    @media (max-width: 1280px) {
      font-size: $font-size-text5;
    }

    @media (max-width: 1024px) {
      font-size: $font-size-text4;
    }

    @media (max-width: 880px) {
      font-size: $font-size-text3;
    }
  }
}

/*Left navigation sidebar-------------------------------------------------------------------------------------------------------------------------------------*/
.navbar {
  .logo,
  .logo-mobile {
    display: block;
    height: 59px;
    padding: 10px 0px;
  }

  .logo-mobile {
    display: none;
  }
}

.sidebar-left-expanded {
  @include make-col(2);
  position: fixed;
  width: 100%;
  height: calc(100% - 120px);
  top: 93px;
  bottom: 0;
  left: 20px;
  border-radius: $default-border-radius;
  margin-left: 0;
  padding: 0;
  z-index: 1032;
  display: block;
  overflow-y: hidden;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;

  .sidebar-left-expanded-content-wrapper {
    height: 100%;
    padding-inline: 16px;
    background-color: $tg-light-background;

    .actions-bar {
      position: relative;
      height: 55px;
      margin: 0 -7px;

      &:before {
        content: "";
        margin-left: -8px;
        margin-right: -8px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 1px;
        //background: $tg-light-background;
      }

      & > ul {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0;
        padding: 0;

        & > li:last-of-type {
          text-align: center;
        }
      }

      a.action-link {
        display: inline-block;
        margin: 7px 20px 7px 0;
        padding: 3px 14px 0;
        @include font-normal();
        color: $tg-main-text;
        border-radius: 20px;
        background-position: 10px 10px;
        border: 1px solid transparent;
        line-height: 24px;

        &:hover {
          background-color: $primary;
        }

        &.selected {
          border: 1px solid $white;
          background-color: $dark-blue;
        }
      }

      a.toggle-expansion {
        display: inline-block;
        margin-bottom: 12px;
        padding: 5px 14px 5px;
        font-size: $font-size-text5;
        color: $tg-main-text !important;
        border-radius: 4px;

        @media (max-width: 1280px) {
          font-size: $font-size-text4;
        }

        @media (max-width: 1024px) {
          font-size: $font-size-text3;
          background-size: auto 13px;
        }

        @media (max-width: 880px) {
          font-size: $font-size-text1;
          background-size: auto 12px;
        }

        &:hover {
          background-color: #015BB4 !important;
          color: #FBFCFD !important;
        }
      }

      .dropup {
        & > ul.dropdown-menu {
          bottom: 7px;
          top: auto;
          left: calc(100% - 15px);
        }
      }

      .dropdown:hover > .dropdown-menu,
      .dropup:hover > .dropdown-menu {
        display: block;
      }
    }

    &.actions-visible {
      .sidebar-nav {
        height: calc(100% - 105px - 59px);
      }

      .actions-bar {
        height: 170px;
      }
    }
  }

  i, .folder-title {
    font-size: $font-size-text10;
  }
}

/* Sidebar navigation */
.sidebar-nav {
  height: calc(100% - 10px - 59px);
  margin-right: -17px;
  margin-bottom: 0;
  margin-left: -15px;

  .panel-group {
    margin: 0;
  }


  a.menu-link {
    display: flex;
    justify-content: flex-start;
    justify-items: flex-start;
    margin-top: -1px;
    padding: 15px 20px 16px 20px;
    transition: color 0.2s;
    text-overflow: ellipsis;
    margin-inline: 16px;

    @media (max-width: 1024px) {
      font-size: $font-size-text4;
    }

    @media (max-width: 880px) {
      font-size: $font-size-text2;
    }

    &:hover {
      background-color: $sidebar-hover-background-element;
      color: $white !important;
      border-radius: 4px;
    }
  }


  .panel {
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: transparent;

    & + .panel {
      margin: 0;
    }

    .panel-heading {
      padding: 0;
      background-color: transparent;
      margin-inline: 16px;

      a {
        position: relative;
        display: flex;
        justify-content: flex-start;
        justify-items: flex-start;
        margin-top: -1px;
        padding: 13px 10px 13px 20px;
        @include font-normal();
        font-size: $font-size-text4;
        color: $tg-main-text;
        font-weight: 600;
        transition: color 0.2s;
        background-color:  $tg-light-background;
        text-overflow: ellipsis;
        align-items: center;

        @media (max-width: 1024px) {
          font-size: $font-size-text4;
        }

        @media (max-width: 880px) {
          font-size: $font-size-text2;
        }

        &:hover {
          background-color: $sidebar-hover-background-element;
          color: $white !important;
          border-radius: 4px;
        }
      }
    }

    &.panel-open > .panel-heading a {
      background-color:  $tg-light-background;;

      &::after {
        background: url("../img/chevron-up.png") no-repeat;
      }
    }

    &.selected {
      & > .panel-heading .panel-title {
        position: relative;
      }

      .accordion-toggle {
        background-color: $primary !important;
      }
    }

    &.single-list-item .panel-heading a::after {
      background: none !important;
    }

    .panel-body {
      padding: 0;
      border: none;

      .dragged-item {
        .list-item {
          background-color: #48657f !important;
        }
      }

      .list-item {
        position: relative;
        padding: 0;
        background-color: $tg-light-background;
        border-radius: 4px;
        margin-inline: 16px;

        &:hover {
          background: $sidebar-hover-background-element;
          color: $white;
        }

        //&:hover > .list-item-link {
        //  color: $black !important;
        //}

        .list-item-link {
          display: flex;
          justify-content: flex-start;
          justify-items: flex-start;
          padding: 13px 10px 13px ($sidebar-spacing + px);
          @include font-normal();
          font-size: $font-size-text4;
          color: $tg-main-text !important;
          transition: color 0.2s;
          word-wrap: break-word;

          @media (max-width: 1024px) {
            font-size: $font-size-text4;
          }

          @media (max-width: 880px) {
            font-size: $font-size-text2;
          }

          &:hover {
            color: $white !important;
          }
        }

        .list-item-dot {
          position: absolute;
          top: 18px;
          width: 8px;
          height: 8px;
          border-radius: 4px;
          background-color: $primary;
        }

        &.selected {
          background-color: $sidebar-selected-background-element;

          .list-item-link {
            color: #FBFCFD !important;
          }

          &:hover > .list-item-link {
            color: $white !important;
          }

          .list-item-dot {
            background-color: $white;
          }
        }

        a {
          text-overflow: ellipsis;
          align-items: center;
        }

        .panel {
          .panel-heading a {
            padding: 12px 24px 12px 23px;
            @include font-normal();
            font-size: $font-size-text5;
            color: $white;
            transition: color 0.2s;
            word-wrap: break-word;
            border: none;
            background-color:  $tg-light-background;;

            &::before {
              content: "\f139";
              position: relative;
              top: -3px;
              left: -6px;
              display: inline-block;
              font-family: "Ionicons";
              font-style: normal;
              font-weight: normal;
              font-size: 21px;
              color: $primary;
              line-height: 1;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
            }

            @media (max-width: 1024px) {
              font-size: $font-size-text4;
            }

            @media (max-width: 880px) {
              font-size: $font-size-text2;
            }

            &:hover {
              color: $sidebar-hover-background-element !important;
            }

            span {
              &.superadmin-edit {
                right: 30px;

                &::before {
                  opacity: 0.2;
                  -webkit-transition: all 0.5s ease;
                  -moz-transition: all 0.5s ease;
                  -ms-transition: all 0.5s ease;
                  -o-transition: all 0.5s ease;
                  transition: all 0.5s ease;
                }
              }
            }

            &:hover {
              span {
                &.superadmin-edit {
                  &::before {
                    opacity: 1;
                  }
                }
              }
            }
          }

          .list-item {
            .list-item-link {
              padding-left: 60px;
            }

            .list-item-dot {
              left: 44px;
              top: 15px;
            }
          }
        }
      }
    }
  }

  .heading-actions {
    margin: 0 2px 0 auto;
    padding: 0 5px 0 5px;
    display: flex;

    > *:last-child {
      margin-left: 10px !important;
    }

    .superadmin-edit {
      align-self: flex-start;
      cursor: pointer;
      font-style: normal;
      font-weight: normal;
      opacity: 0.2;
      transition: all 0.5s ease;

      &:hover {
        opacity: 1;
      }
    }

    .acc-arrow {
      align-self: flex-start;
      opacity: 0.6;
      margin: auto;
    }
  }
}

.sidebar-left-collapsed {
  position: fixed;
  top: 93px;
  bottom: 0;
  left: 20px;
  z-index: -1;
  width: 44px;
  background-color: $tg-light-background;
  transition: opacity 0.3s ease;
  transition-delay: 0.3s;
  opacity: 0;
  border-radius: $default-border-radius;

  .logo {
    display: block;
    margin-top: 20px;
  }

  a.toggle-expansion {
    display: inline-block;
    position: absolute;
    top: calc(50vh + 48px);
    left: 5px;
    width: 120px;
    padding: 6px 14px 4px;
    font-size: $font-size-text5;
    color: $tg-main-text !important;
    -ms-transform: rotate(270deg); /* IE 9 */
    -ms-transform-origin: left top; /* IE 9 */
    -webkit-transform: rotate(270deg); /* Chrome, Safari, Opera */
    -webkit-transform-origin: left top; /* Chrome, Safari, Opera */
    transform: rotate(270deg);
    transform-origin: left top;
    border-radius: 4px;
    text-align: center;

    &:hover {
      background-color: $sidebar-hover-background-element;
      color: $white !important;
    }
  }
}

/*Left sidebar panels general styling-------------------------------------------------------------------------------------------------------------------------*/
.sidebar-left-panel {
  @include font-normal();
  font-size: $font-size-text4;
  color: $very-dark-grey;
  background-color: $light-grey;

  @media (max-width: 1280px) {
    font-size: $font-size-text3;
  }

  @media (max-width: 1024px) {
    font-size: $font-size-text2;
  }

  @media (max-width: 880px) {
    font-size: $font-size-text0;
  }

  h1 {
    margin-top: 16px;
    margin-bottom: 19px;
    @include font-black();
    font-size: $font-size-text6;
    line-height: 1.2em;

    @media (max-width: 1280px) {
      font-size: $font-size-text5;
    }

    @media (max-width: 1024px) {
      font-size: $font-size-text4;
    }

    @media (max-width: 880px) {
      font-size: $font-size-text2;
    }

    img {
      margin-top: -3px;
    }

    .subtitle {
      @include font-medium();
      font-size: $font-size-text4;

      @media (max-width: 1280px) {
        font-size: $font-size-text3;
      }

      @media (max-width: 1024px) {
        font-size: $font-size-text2;
      }

      @media (max-width: 880px) {
        font-size: $font-size-text0;
      }
    }
  }

  .section-extra-margin-top {
    margin-top: 30px;
  }

  .form-group {
    @include form-group();
  }

  .form-style-labels-inside-inputs-condensed {
    .form-group {
      @include form-group();

      .form-control {
        font-size: $font-size-text5;

        @media (max-width: 1280px) {
          font-size: $font-size-text3;
        }

        @media (max-width: 1024px) {
          font-size: $font-size-text2;
        }

        @media (max-width: 880px) {
          font-size: $font-size-text0;
        }
      }

      label {
        color: $very-dark-grey;
        background-color: $lighter-grey;
        font-size: $font-size-text5;

        @media (max-width: 1280px) {
          font-size: $font-size-text3;
        }

        @media (max-width: 1024px) {
          font-size: $font-size-text2;
        }

        @media (max-width: 880px) {
          font-size: $font-size-text0;
        }
      }
    }
  }

  p {
    margin-top: 33px;
  }

  .btn-container {
    margin-top: 33px;
    text-align: center;

    .btn-primary {
      padding: 7px 20px 5px;
    }
  }

  .collapse-arrow-container {
    margin-top: 28px;
    padding-top: 8px;
    text-align: center;
  }
}

/*Left sidebar view panel-------------------------------------------------------------------------------------------------------------------------------------*/
.sidebar-left-panel-view-collapsed {
  visibility: hidden;
  position: fixed;
  top: 60px;
  bottom: 0;
  left: 44px;
  z-index: 997;
  display: block;
  width: 44px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  background-color: $light-grey;

  & > ul {
    padding-left: 0;

    & > li a {
      display: inline-block;
      padding: 8px 12px 13px;
    }
  }
}

.sidebar-left-panel-view-expanded {
  @include make-col(2);
  visibility: hidden;
  position: fixed;
  top: 60px;
  bottom: 0;
  left: 44px;
  margin-left: 0;
  padding: 0 20px !important;
  z-index: 997;
  display: block;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  -webkit-overflow-scrolling: touch;
  border-right: 1px solid $grey4;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

/*Left sidebar save panel-------------------------------------------------------------------------------------------------------------------------------------*/
.sidebar-left-panel-save {
  @include make-col(2);
  visibility: hidden;
  position: fixed;
  top: 60px;
  bottom: 0;
  left: 44px;
  margin-left: 0;
  padding: 0 20px !important;
  z-index: 998;
  display: block;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  -webkit-overflow-scrolling: touch;
  border-right: 1px solid $grey4;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;

  select.form-control {
    margin-bottom: 5px;
  }
}

/*Left sidebar subscribe panel-------------------------------------------------------------------------------------------------------------------------------------*/
.sidebar-left-panel-subscribe {
  @include make-col(2);
  visibility: hidden;
  position: fixed;
  top: 60px;
  bottom: 0;
  left: 44px;
  margin-left: 0;
  padding: 0 20px !important;
  z-index: 998;
  display: block;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  -webkit-overflow-scrolling: touch;
  border-right: 1px solid $grey4;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;

  select.form-control {
    margin-bottom: 5px;
  }

  .time-selector {
    .col-xs-4 {
      width: calc(33% - 8px);
      padding: 0 2px;

      select.form-control {
        padding-left: 6px;
        padding-right: 7px;
        background-position: right 2px center;
      }
    }

    .col-xs-4:first-child {
      width: calc(33% + 4px);
      padding-left: 15px;
    }

    .col-xs-4:last-child {
      width: calc(33% + 4px);
      padding-right: 15px;
    }
  }
}

/*Page center column------------------------------------------------------------------------------------------------------------------------------------------*/
.page-center-column {
  @include make-col-with-deduction(10);
  @include make-col-offset(2);

  background-color: $white;
  width: 100%;
  height: calc(100% - 120px);
  padding: ($grid-gutter-width / 2) ($grid-gutter-width / 2) 0 ($grid-gutter-width / 2);
  position: absolute !important;
  top: 94px !important;
  left :30px;
  bottom: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  border: 1px solid $light-grey;
  border-radius: $default-border-radius;

  .loading-adjust-huener {
    position: fixed;
    top: calc(50% - 45px);
    left: calc(50% - 45px);
    height: 90px;
    width: 90px;
    z-index: 1040;
    background: transparent url("../img/loading.gif") center no-repeat;

    &:before {
      content: "Loading...";
      position: absolute;
      bottom: -45px;
      left: 15px;
      @include font-medium();
      font-size: $font-size-text2;
      text-transform: uppercase;
    }

    &:after {
      content: "";
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  .filters {
    border-bottom: 1px solid $darker-grey;

    .form-group {
      margin-top: 5px;

      label {
        text-transform: uppercase;
      }
    }
  }

  .page-title {
    display: table;
    width: 100%;
    margin: 12px 0 17px;

    &.row {
      display: block;
      width: auto;
      margin: 0 -15px;
    }

    &.extra-spacing-bottom {
      margin-bottom: 25px;
    }

    h1 {
      display: table-cell;
      margin-bottom: 12px;
      vertical-align: middle;
      @include font-normal();
      font-size: $font-size-text9;
      text-transform: none;
      color: $very-dark-grey;

      @media (max-width: 1280px) {
        font-size: $font-size-text8;
      }

      @media (max-width: 1024px) {
        font-size: $font-size-text7;
      }

      @media (max-width: 880px) {
        font-size: $font-size-text6;
      }

      img {
        margin-top: -3px;
        margin-right: 5px;
      }
    }

    .action-buttons {
      display: table-cell;
      text-align: right;

      a {
        @include font-normal();
        font-size: $font-size-text5;

        @media (max-width: 1280px) {
          font-size: $font-size-text4;
        }

        @media (max-width: 1024px) {
          font-size: $font-size-text3;
        }

        @media (max-width: 880px) {
          font-size: $font-size-text1;
        }

        img {
          margin-right: 5px;
          margin-top: -4px;
        }
      }

      .input-search {
        min-width: 220px;
        padding: 4px 4px 5px;
        border: 1px solid $grey;
        border-radius: 3px;
        line-height: 20px;
      }
    }
  }

  .page-title-underlined {
    margin-bottom: 20px;
    @include font-black();
    font-size: $font-size-text6;
    border-bottom: 1px solid $darker-grey;

    @media (max-width: 1280px) {
      font-size: $font-size-text5;
    }

    @media (max-width: 1024px) {
      font-size: $font-size-text4;
    }

    @media (max-width: 880px) {
      font-size: $font-size-text2;
    }

    h1 {
      margin-bottom: 18px;
    }
  }

  h1 {
    margin-bottom: 30px;
    font-size: $font-size-text4;
    text-transform: uppercase;

    @media (max-width: 1280px) {
      font-size: $font-size-text3;
    }

    @media (max-width: 1024px) {
      font-size: $font-size-text2;
    }

    @media (max-width: 880px) {
      font-size: $font-size-text0;
    }
  }

  .bordered-area {
    width: 325px;
    min-height: 300px;
    border: 2px solid $light-green;
  }

  .actions {
    margin-top: 30px;
  }
}

/*Right slide bar---------------------------------------------------------------------------------------------------------------------------------------------*/

.sidebar-right {
  @include make-col(3);
  margin-right: -(percentage((3 / $grid-columns)));

  position: fixed;
  top: 63px;
  bottom: 0;
  right: 0;
  padding: 0;
  z-index: 1035;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  @include font-normal();
  font-size: $font-size-text5;
  border-left: 1px solid $grey;
  border-top: 1px solid $grey;
  background: $white;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;

  @media (max-width: 1280px) {
    font-size: $font-size-text4;
  }

  @media (max-width: 1024px) {
    font-size: $font-size-text3;
  }

  @media (max-width: 880px) {
    font-size: $font-size-text1;
  }

  .sidebar-right-close {
    display: block;
    margin: 18px 0 2px;
    text-align: center;
  }

  .sidebar-header {
    margin-top: 33px;
    margin-bottom: 13px;
    padding: 20px 30px 16px;
    font-size: $font-size-text7;
    border-top: 1px solid $grey;
    border-bottom: 1px solid $grey;
    background-color: $light-grey;

    @media (max-width: 1024px) {
      padding: 15px 15px 11px;
    }

    &.first-section {
      margin-top: 15px;
    }

    img {
      margin-top: -2px;
      margin-right: 9px;
      opacity: 0.75;

      &.profile-img {
        width: 14px;
      }

      &.mail-img {
        width: 16px;
        margin-top: -4px;
      }
    }

    .action-links {
      text-align: right;

      @media (max-width: 992px) {
        /** media query to match col-sm-* of bootstrap **/
        text-align: left;
      }

      a {
        margin-left: 10px;
        white-space: nowrap;
        float: right;

        @media (max-width: 992px) {
          /** media query to match col-sm-* of bootstrap **/
          margin-left: 0;
          padding-left: 0;
          float: none;
        }

        font-size: $font-size-text4;

        @media (max-width: 1280px) {
          font-size: $font-size-text3;
        }

        @media (max-width: 1024px) {
          font-size: $font-size-text2;
        }

        @media (max-width: 880px) {
          font-size: $font-size-text0;
          line-height: 14px;
        }

        img {
          height: 11px;
          margin-top: -4px;
          margin-right: 4px;

          &.save-icon {
            height: 11px;
          }
        }
      }
    }

    & > div {
      &:not(.action-links) {
        display: flex;
        align-items: center;
        align-self: center;

        & > span {
          display: flex;
          align-items: center;
        }

        i {
          &.icon {
            align-self: center;
            margin-right: 5px;
          }
        }
      }
    }
  }

  .sidebar-body {
    padding: 0 30px;
  }

  .switch {
    &:focus,
    *:focus {
      outline: none;
    }
  }

  /*RSB messages accordion styling*/
  .panel-group {
    margin-bottom: 0;

    .panel {
      border: none;
      border-radius: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
      background-color: $light-grey;
      outline: none;

      &,
      a {
        @include font-medium();
        font-size: $font-size-text5;
        color: $very-dark-grey !important;

        @media (max-width: 1280px) {
          font-size: $font-size-text4;
        }

        @media (max-width: 1024px) {
          font-size: $font-size-text3;
        }

        @media (max-width: 880px) {
          font-size: $font-size-text1;
        }
      }

      &.new-message {
        background-color: $lighter-grey;
      }

      &.deleted-message {
        background-color: $lighter-grey;
      }
    }

    .panel-heading {
      position: relative;
      padding: 0;
      border: none;
      background-color: transparent;

      .panel-title .accordion-toggle > span > span {
        display: table;
        width: 100%;
        padding: 13px 16px;

        & > * {
          display: table-cell;
          vertical-align: top;
          text-align: right;
        }

        span:first-child {
          text-align: left;
        }

        a:nth-last-child(2),
        a:last-child {
          width: 27px;
        }

        img {
          margin-top: -3px;
          padding-left: 18px;
        }
      }
    }

    .panel-body {
      border: 0;
    }
  }

  ul.item-list {
    margin-top: 4px;
    padding: 0;

    li {
      display: block;
      margin-bottom: 5px;

      a {
        display: block;
        padding: 9px !important;
        font-size: $font-size-text4;
        color: $very-dark-grey;
        border: 1px solid $grey;
        border-radius: 3px;

        @media (max-width: 1280px) {
          font-size: $font-size-text4;
        }

        @media (max-width: 1024px) {
          font-size: $font-size-text3;
        }

        @media (max-width: 880px) {
          font-size: $font-size-text1;
        }
      }
    }
  }

  .sidebar-right-bottom-nav {
    margin-top: 15px;

    a {
      font-size: $font-size-text5;

      @media (max-width: 1280px) {
        font-size: $font-size-text4;
      }

      @media (max-width: 1024px) {
        font-size: $font-size-text3;
      }

      @media (max-width: 880px) {
        font-size: $font-size-text1;
      }
    }
  }

  .delivery-item {
    position: relative;
    margin-bottom: 5px;
    padding: 9px 50px 9px 0;
    font-size: $font-size-text5;

    @media (max-width: 1280px) {
      font-size: $font-size-text4;
    }

    @media (max-width: 1024px) {
      font-size: $font-size-text3;
    }

    @media (max-width: 880px) {
      font-size: $font-size-text1;
    }

    .delivery-item-controls {
      position: absolute;
      top: 8px;
      right: 10px;
    }
  }

  .sidebar-right-panel-with-footer {
    position: relative;
    min-height: calc(100vh - 115px);
    padding-bottom: 130px;

    footer {
      display: block;
      position: absolute;
      bottom: 15px;
      left: 0;
      width: 100%;
      text-align: center;
    }
  }
}

/*Edit subscription popup-------------------------------------------------------------------------------------------------------------------------------------*/

.edit-subscription-popup .time-selector {
  label {
    width: 35%;
    padding: 3px 5px 3px 5px !important;
  }

  select.form-control {
    padding-top: 3px;
    padding-bottom: 3px;
  }
}

/*Scrollbars styling (works in Chrome and Safari only!)-------------------------------------------------------------------------------------------------------*/

::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-track {
  background-color: $grey2;
}

::-webkit-scrollbar-thumb:horizontal {
  border: 1px solid $darker-grey2;
  border-radius: 15px;
  background: -webkit-linear-gradient(
          top,
          $lighter-grey2 0%,
          $lighter-grey2 33%,
          $darker-grey3 76%,
          $darker-grey2 100%
  );
}

::-webkit-scrollbar-thumb:vertical {
  border: 1px solid $darker-grey2;
  border-radius: 15px;
  background: -webkit-linear-gradient(
          left,
          $lighter-grey2 0%,
          $lighter-grey2 33%,
          $darker-grey3 76%,
          $darker-grey2 100%
  );
}

::-webkit-scrollbar-button:horizontal:decrement {
  background-image: url("../img/scrollbar-arrow-left.png");
}

::-webkit-scrollbar-button:horizontal:increment {
  background-image: url("../img/scrollbar-arrow-right.png");
}

::-webkit-scrollbar-button:vertical:decrement {
  background-image: url("../img/scrollbar-arrow-up.png");
}

::-webkit-scrollbar-button:vertical:increment {
  background-image: url("../img/scrollbar-arrow-down.png");
}

::-webkit-scrollbar-corner {
  background-color: $grey2;
}

/*Generated report CSS overrides------------------------------------------------------------------------------------------------------------------------------*/
div.a16 {
  height: auto !important;
}

tr[valign="top"] > td {
  height: auto !important;
}

/*Loading bar-------------------------------------------------------------------------------------------------------------------------------------------------*/
.loading-bar-overlay {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1039;
  background-color: $light-grey;

  &:before {
    content: "";
    position: fixed;
    top: calc(50% - 115px);
    left: calc(50% - 40px);
    width: 90px;
    height: 40px;
    background: url("../img/tg-logo.png") no-repeat;
  }

  #loading-bar {
    position: fixed;
    top: calc(50% - 57px);
    width: 400px;
    height: 22px;
    z-index: 1040;
    overflow: hidden;
    border: 1px solid $very-dark-grey;
    border-radius: 5px;
    box-shadow: 0 0 5px;
    background-color: $dark-grey;

    &:before {
      content: attr(data-translation-loading);
      position: fixed;
      top: calc(50% - 57px);
      display: block;
      width: 400px;
      @include font-medium();
      font-size: 13px;
      text-align: center;
      text-transform: uppercase;
    }

    div {
      height: 100%;
      color: $light-grey;
      font-size: $font-size-text4;
      padding-right: 0;
      text-align: right;
      line-height: 22px;
      width: 0;
      background-color: $primary;
      background: -webkit-gradient(linear, 0% 0%, 0% 100%, from($light-green), to($primary));
      background: -webkit-linear-gradient(top, $light-green, $primary);
      background: -moz-linear-gradient(top, $light-green, $primary);
      background: -ms-linear-gradient(top, $light-green, $primary);
      background: -o-linear-gradient(top, $light-green, $primary);
    }
  }

  .steps {
    position: fixed;
    bottom: 20px;
    left: calc(50% - 117px);
  }
}

/*Animations--------------------------------------------------------------------------------------------------------------------------------------------------*/
@-moz-keyframes ringing {
  0% {
    -moz-transform: rotate(-15deg);
  }
  2% {
    -moz-transform: rotate(15deg);
  }
  4% {
    -moz-transform: rotate(-18deg);
  }
  6% {
    -moz-transform: rotate(18deg);
  }
  8% {
    -moz-transform: rotate(-22deg);
  }
  10% {
    -moz-transform: rotate(22deg);
  }
  12% {
    -moz-transform: rotate(-18deg);
  }
  14% {
    -moz-transform: rotate(18deg);
  }
  16% {
    -moz-transform: rotate(-12deg);
  }
  18% {
    -moz-transform: rotate(12deg);
  }
  20% {
    -moz-transform: rotate(0deg);
  }
}

@-webkit-keyframes ringing {
  0% {
    -webkit-transform: rotate(-15deg);
  }
  2% {
    -webkit-transform: rotate(15deg);
  }
  4% {
    -webkit-transform: rotate(-18deg);
  }
  6% {
    -webkit-transform: rotate(18deg);
  }
  8% {
    -webkit-transform: rotate(-22deg);
  }
  10% {
    -webkit-transform: rotate(22deg);
  }
  12% {
    -webkit-transform: rotate(-18deg);
  }
  14% {
    -webkit-transform: rotate(18deg);
  }
  16% {
    -webkit-transform: rotate(-12deg);
  }
  18% {
    -webkit-transform: rotate(12deg);
  }
  20% {
    -webkit-transform: rotate(0deg);
  }
}

@-ms-keyframes ringing {
  0% {
    -ms-transform: rotate(-15deg);
  }
  2% {
    -ms-transform: rotate(15deg);
  }
  4% {
    -ms-transform: rotate(-18deg);
  }
  6% {
    -ms-transform: rotate(18deg);
  }
  8% {
    -ms-transform: rotate(-22deg);
  }
  10% {
    -ms-transform: rotate(22deg);
  }
  12% {
    -ms-transform: rotate(-18deg);
  }
  14% {
    -ms-transform: rotate(18deg);
  }
  16% {
    -ms-transform: rotate(-12deg);
  }
  18% {
    -ms-transform: rotate(12deg);
  }
  20% {
    -ms-transform: rotate(0deg);
  }
}

@keyframes ringing {
  0% {
    transform: rotate(-15deg);
  }
  2% {
    transform: rotate(15deg);
  }
  4% {
    transform: rotate(-18deg);
  }
  6% {
    transform: rotate(18deg);
  }
  8% {
    transform: rotate(-22deg);
  }
  10% {
    transform: rotate(22deg);
  }
  12% {
    transform: rotate(-18deg);
  }
  14% {
    transform: rotate(18deg);
  }
  16% {
    transform: rotate(-12deg);
  }
  18% {
    transform: rotate(12deg);
  }
  20% {
    transform: rotate(0deg);
  }
}

/*Chart accordion---------------------------------------------------------------------------------------------------------------------------------------------*/

.chart-accordion {
  .card-default > .card-heading {
    border-color: $grey;
    background-color: transparent;
  }

  input[type="text"],
  input[type="number"],
  select {
    @include form-style-bordered-inputs-form-control();

    margin: 0 !important;
    padding: 4px 9px !important;
    font-size: $font-size-text5;
    line-height: 32px;

    &:focus {
      padding: 4px 9px !important;
    }
  }

  .column-remove {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}

#chart-preview {
  .number-display-widget {
    &.dc-chart {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

/*Set height for elastic textarea-----------------------------------------------------------------------------------------------------------------------------------------------*/
textarea {
  &[msd-elastic],
  &.msd-elastic {
    height: 40px !important;
  }
}

/* Style for time selector
  and set horizontal label--------------------------------------------------------------------------------------------------------*/

.horizontal-label {
  label {
    display: inline-block;
    border: 1px solid $grey;
    border-right: none;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    height: 40px;
    line-height: 40px;
    width: 20%;
    padding-left: 9px;
    overflow: hidden;

    & + select,
    & + input {
      display: inline-block;
      width: 80%;
      border-left: none !important;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
}

/* Overriding modal footer
   button style ----------------------------------------------------------------------------------------------------*/
.modal-footer {
  .btn {
    + .btn {
      margin-bottom: 3px;
    }
  }
}

/* Overriding form-control to show
   input field inline ---------------------------------------------------------------------------------------------*/
.form-group-inline {
  .form-control-inline {
    display: inline;
  }
}

.list-values {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  flex-wrap: wrap;
}

.list-values-container {
  background-color: $light-grey;
  width: 265px;
  padding: 15px;
  margin-bottom: 10px;
  margin-right: 10px;

  .heading {
    margin-bottom: 10px;
    border-bottom: 1px solid;
  }
}

.list-value {
  position: relative;

  .ion-close-circled {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
}

.loadingBar {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: $light-grey;
}

.loadingBar::before {
  content: "";
  position: fixed;
  top: calc(50% - 120px);
  left: calc(50% - 180px);
  width: 375px;
  height: 65px;
  background: url("../img/tg-logo.png") no-repeat;
}

.loadingBlock {
  position: fixed;
  top: calc(50% - 61px);
  left: calc(50% - 200px);
  text-align: center;
}

.loadingIndicator {
  position: fixed;
  margin-top: 15px;
  width: 400px;
  height: 22px;
  z-index: 1040;
  border: 1px solid $very-dark-grey;
  border-radius: 5px;
  box-shadow: 0 0 5px $dark-grey;
  text-align: center;
  display: block;
  background-color: $dark-grey;
  top: calc(50% - 61px);
  left: calc(50% - 200px);
  overflow: hidden;
}

.loading-text {
  position: fixed;
  top: calc(50% - 40px);
  left: calc(50% - 200px);
  display: block;
  width: 400px;
  font-size: 13px;
  text-align: center;
  z-index: 1050;
  color: $light-black;

  @include font-bold();
  line-height: 11px;
}

.loadingIndicatorGreen {
  height: 100%;
  top: 0;
  left: 0;
  transition: ease 0.4s;
  text-align: right;
  line-height: 22px;
  color: $light-grey;
  font-size: 13px;
  background-color: $primary;
  padding-right: 0;
  width: 0;
  background: -webkit-gradient(linear, 0% 0%, 0% 100%, from($tg-light-blue), to($primary));
}

@media (max-width: 425px) {
  .loadingIndicator {
    width: 300px;
    left: calc(50% - 150px);
  }

  .loading-text {
    width: 300px;
    left: calc(50% - 150px);
  }
}

.bootstrap-switch-group {
  display: flex;
  align-items: center;
}

.page-center {
  display: inline;
  left: calc(30% - 50px);
  padding: 0 15px 15px 0;
  margin: 10px;
  text-align: center;
  font-size: 24px;
  font-style: initial;
}

timepicker {
  .form-group {
    max-width: 60px;
  }
}

bs-datepicker-container,
bs-daterangepicker-container {
  z-index: 1500;
}

// comment section css
.comment-box {
  width: 100%;
  height: auto;
  border-radius: 10px;
  background-color: $light-grey;
  padding: 15px;

  .title {
    font-size: 18px;
    color: $primary;
    font-weight: bold;
  }

  .comment-list-box {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .comment-thumbnail {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      background-color: $white;
      overflow: hidden;

      img {
        width: 100%;
        height: auto;
      }
    }

    .comment-data {
      width: calc(100% - 58px);
      height: auto;
      margin-left: 20px;
      transition: padding 0.3s, background-color 1.5s;

      &.highlight {
        padding: 10px;
        background-color: #fdefd4;
        border-radius: 5px;
      }

      .mention {
        font-weight: bold;
        cursor: pointer;
      }

      .userName {
        font-size: 18px;
        font-weight: bold;
        color: $very-dark-grey;
        margin-bottom: 7px;

        .time {
          font-size: 10px;
          font-weight: 500;
          color: $dark-grey;
        }
      }

      .userMessage {
        font-size: 14px;
        color: $very-dark-grey;
      }

      .reply-tool {
        width: 100%;
        height: auto;

        .message-action .message-box {
          margin-left: 0;
        }

        .reply-text,
        .like-text {
          font-size: 10px;
          color: $primary;
          display: inline-block;
          vertical-align: middle;
          margin-right: 20px;
          cursor: pointer;
        }

        .reply-text {
          a {
            font-size: 10px;
            color: $primary;
          }
        }

        .like-text {
          color: $very-dark-grey;
          margin-right: 0px;

          .like-icon-fill {
            margin-right: 5px;
          }

          .like-icon-empty {
            display: none;
            margin-right: 5px;
          }
        }
      }
    }
  }

  .mention-autocomplete {
    background-color: $light-grey;
    border: 1px solid;
    border-radius: 5px;
    line-height: 24px;
    max-height: 200px;
    overflow-y: auto;
    color: $black;

    > * {
      padding: 5px 15px;
      cursor: pointer;

      &:hover {
        background-color: $grey;
      }

      &:first-child {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }

      &:last-child {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }

  .comment-replies {
    padding-left: 60px;
    //border-left: 1px solid;
  }

  .message-action {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;

    .user-thumbnail {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      background-color: $white;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .message-box {
      width: 100%;
      max-width: 700px;
      height: auto;
      margin-left: 20px;

      .comment-textbox {
        width: 100%;
        height: 38px;
        background-color: transparent;
        border: 1px solid $grey;
        border-radius: 50px;
        color: $very-dark-grey;
        font-size: 14px;
        font-weight: bold;
      }

      > .attachments > .attachment {
        padding: 10px 20px;
        background-color: #ededed;
        border-radius: 5px;

        .a-label {
          color: $primary;
          font-weight: bold;
        }

        .a-filename {
          color: #B5B5B5;
          font-weight: bold;
          font-size: 10px;
        }
      }
    }

    .message-submit {
      height: auto;

      button {
        width: min-content;
      }
    }
  }
}


.search-bar {
  background-color:  $tg-light-background;
  padding-top: 16px;
  padding-right: 0 !important;
  margin-inline: 16px;

  label {
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
  }

  input {
    width: 100%;
    height: 48px;
    border: none;
    outline: none;
    padding: 15px 24px 16px ($sidebar-spacing + px);
    background-color: #FBFCFD;
    color: $tg-main-text;
    border-radius: 4px;

    &::placeholder {
      color: $tg-main-text;
    }
  }
}

@include sidebar-level($sidebar-spacing, 0);
@include sidebar-level($sidebar-spacing, 1);
@include sidebar-level($sidebar-spacing, 2);
@include sidebar-level($sidebar-spacing, 3);
@include sidebar-level($sidebar-spacing, 4);

// Side-Bar Search Box Css End For White Theme

@import "reports-styles";

.help-block .list-unstyled {
  span, i {
    color: #a94442;
  }
}

#imp-search-bar {
  width: 100%;
  height: 47px;
  border: none;
  outline: none;
  padding: 15px 24px 16px 20px;
}

.sortable-ghost {
  background-color: $light-green;
}

@media (max-width: 426px) {
  .recaptcha-container {
    transform: scale(0.7);
    margin-left: -43px;
  }
}

.course-container {
  border-radius: 10px;
  box-shadow: 10px 10px 50px 0 rgba(0, 0, 0, 0.1);
  padding: 15px;

  .rate {
    background-color: #f4f4f4;
    padding: 5px 15px;
    border-radius: 3px;
    width: max-content;

    @media (max-width: 475px) {
      width: auto;
    }
  }

  .summary {
    dl, ul, ol {
      margin-top: 0;
      margin-bottom: 1rem;
    }

    ul {
      list-style: disc;

      ul {
        list-style: circle;
      }
    }

    ol {
      list-style: decimal;
    }
  }
}

.course-info {
  margin: 15px;
  border-radius: 15px;
  box-shadow: 10px 10px 50px 0 rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
  min-height: 500px;

  h5 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: $very-dark-grey;
  }

  .summary {
    @media (min-width: 768px) {
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  @media (max-width: 767px) {
    min-height: auto;
  }

  .c-image-container {
    height: 260px;
    min-height: 260px;
    border-radius: 15px 15px 0 0;
    background-size: cover;
    background-position-x: center;

    @media (max-width: 425px) {
      height: 210px;
      min-height: 210px;
    }
  }

  .badges {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: 5px;

    img {
      max-width: 90px;
    }
  }

  .progress {
    border-radius: 10px;
    margin: 3px 0;
    background-color: #c1e8e5;
    font-size: 14px;
    height: auto;

    .progress-bar {
      padding: 13px 6px;
      border-radius: 30px;
      background-color: $primary;
      text-align: right;

      @media (max-width: 1280px) {
        font-size: $font-size-text4;
        padding: 12px 6px;
      }

      @media (max-width: 1024px) {
        font-size: $font-size-text3;
        padding: 11px 6px;
      }

      @media (max-width: 880px) {
        font-size: $font-size-text1;
        padding: 10px 6px;
      }
    }
  }
}

.rate {
  rating {
    margin-left: 6px;

    span {
      margin-right: 6px;
    }
  }
}

nav {
  .avatar {
    height: 20px;
    border-radius: 30px;
  }


}

#user-dropdown {
  width: 160px;
  min-width: auto;
  border: 1px solid $grey;
  border-radius: 3px;
  margin-top: 14px;
  z-index: 1036;
  background: $tg-light-background;

  .divider {
    margin: 0;
  }

  li {
    min-width: auto;
    background: none;
    border-bottom: none;
    color: $tg-main-text;

    a {
      color: $tg-main-text;
      font-weight: 600;
      font-size: $font-size-text4;
    }
    i{
      top: 2px;
      position: relative;
      font-size: $font-size-text10;
      margin-right: 10px;
    }
  }
}

.course-nav {
  display: flex;
  justify-content: space-between;
  padding: 0 18px;

  .search {
    padding: 4px;
    border-bottom: 2px solid $primary;

    input {
      border: none;
      outline: none;
      background: none;
      color: $very-dark-grey;
    }
  }

  .courses-switch {
    padding-top: 4px;

    span {
      padding-bottom: 5px;
      cursor: pointer;

      &.selected {
        color: $primary;
        border-bottom: 2px solid $primary;
      }
    }
  }
}

.news-info {
  margin: 15px;
  border-radius: 15px;
  box-shadow: 10px 10px 50px 0 rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
  min-height: 450px;

  .summary {
    @media (min-width: 768px) {
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  h5 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: $very-dark-grey;
  }

  @media (max-width: 767px) {
    min-height: auto;
  }

  .c-image-container {
    height: 260px;
    min-height: 260px;
    border-radius: 15px 15px 0 0;
    background-size: cover;
    background-position-x: center;

    @media (max-width: 425px) {
      height: 210px;
      min-height: 210px;
    }
  }
}

.news-container {
  .search {
    padding: 4px;
    border-bottom: 2px solid $primary;

    input {
      border: none;
      outline: none;
      background: none;
      color: $very-dark-grey;
    }
  }
}

.boards {
  height: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  margin: 0 -15px;
  padding: 0 15px 15px 15px;
}

.board {
  max-width: 400px;
  height: 100%;
  overflow: hidden;
  width: 50px;

  .sortable-ghost {
    background-color: initial;
  }

  &.card {
    border-top: 3px solid #c6c6c6;
  }

  > .card-body {
    padding: 0.5rem;
  }

  .card-header {
    background-color: initial;
    align-items: center;
    min-height: 49px;
    padding: 0.5rem 1.25rem;
  }

  .label {
    padding: 5px 10px;
    border-radius: 30px;
  }

  &.collapsed {
    .card-header {
      writing-mode: vertical-rl;
      text-orientation: mixed;
      padding: 15px;
      align-items: baseline;

      > * {
        margin-bottom: 20px;
      }
    }
  }

  &.expanded {
    min-width: 400px;

    .card-header {
      border-bottom: 1px solid #dfdfdf !important;
    }

    .issues-counter {
      margin-left: auto
    }
  }

  .user-avatar img {
    border-radius: 30px;
  }
}

.markdown {
  word-break: break-word;

  img {
    max-width: 100%;
    margin: 0 0 8px;
  }

  dl, ul, ol {
    margin-top: 0;
    margin-bottom: 1rem;
    padding-left: 0;

    li {
      margin-left: 28px;
    }
  }

  ul {
    list-style: disc;

    li > input[type="checkbox"] {
      margin-right: 0.25rem;
    }
  }

  ol {
    list-style: decimal;
  }

  table {
    margin: 16px 0;
    border: 0;
    width: auto;
    display: block;
    overflow-x: auto;

    tr th {
      border-bottom: solid 2px #ccc
    }

    td, th {
      border: 1px solid #dfdfdf;
      padding: 0.75rem;
    }
  }

  blockquote {
    color: #7f8fa4;
    font-size: inherit;
    padding: 8px 24px;
    margin: 16px 0;
    border-left: 3px solid #eaeaea;

    > *:last-child {
      margin: 0;
    }
  }

  code {
    padding: 4px 4px 2px 4px;
    border-radius: 4px;
    background-color: #f4f4f4;
  }

  pre {
    border: 1px solid #e1e1e1;
    border-radius: 6px;
    background-color: #f4f4f4;
    padding: 10px;

    code {
      padding: 0;
      border-radius: 0;
      background-color: initial;
    }
  }

  h1 {
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
    font-size: 2.5rem;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}

#sidebar-left-accordion {
  .card-header {
    border-bottom: none !important;
  }
}

.markdown-editor {
  textarea {
    outline: none;
    border: none;
    padding: 0;
  }

  .card-body, .card-body textarea {
    min-height: 300px !important;
    width: 100%;
  }

  .card-header, .card-footer {
    background-color: initial !important;
  }

  .card-body {
    img {
      max-width: 100%;
    }
  }

  .active {
    border-bottom: 3px solid $primary;
  }

  .btn-toolbar {
    > span {
      padding: 6px 15px;
      margin-top: 3px;
      margin-bottom: 3px;
      cursor: pointer;
    }

    > * {
      margin-right: 0.5rem;

      &:last-child {
        margin-right: 0;
      }
    }

    .btn-sm {
      padding: 6px;
    }
  }

  .markdown-toolbar .btn {
    color: $primary;
  }

  .card-footer {
    color: $primary;
  }
}

img.avatar {
  border-radius: 9999px;
}

.issue-labels {
  .label {
    padding: 5px 10px;
    border-radius: 30px;
  }

  > * {
    margin-right: 5px;
    margin-bottom: 5px;
    background-color: #c6c6c6;

    &:last-child {
      margin-right: 0;
    }
  }
}

.typeahead-results {
  position: absolute;
  display: block;
  height: auto;
  visibility: inherit;
  z-index: 10;
  border-radius: 5px;
  background-color: $white;
  border: 1px solid #e1e1e1;
  padding: 5px 0;
  max-width: 100%;
  word-break: break-all;

  > * {
    cursor: pointer;
    padding: 0.25rem 1.5rem;

    &:hover {
      color: $white;
      background-color: $primary;
    }
  }
}


ngb-datepicker, ngb-timepicker {
  .btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    &:focus {
      box-shadow: none !important;
    }
  }
}

.datetime-picker {
  .ngb-tp {
    display: flex;
    justify-content: center;
  }
}


.btn-very-dark-grey {
  @include button-style($very-dark-grey, $white);
}

.no-pointer{
  pointer-events: none;
  cursor: default;
  color: gray;
  text-decoration: none;
}

.acc-arrow{
  color: $tg-main-text;
}

.accordion-toggle:hover .acc-arrow{
    color: $white !important;
}
