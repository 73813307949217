@media (max-width: 1360px) {

  .actions-header-bar input.input-search {
    min-width: 0;
  }
}

@media (max-width: 1160px) {
  .sidebar-left-expanded .sidebar-left-expanded-content-wrapper .logo img {
    width: auto;
    max-width: 100%;
    max-height: 100%;
  }
}

@media (min-width: 1080px) {
  .navbar-header {
    float: left;

    .navbar-toggle {
      display: none;
    }
  }

  .navbar .collapse {
    display: block;
  }
}

@media (min-width: 768px) {
  .modal-dialog {
    max-width: 600px;
    margin: 30px auto;
  }
}

@media (max-width: 1080px) {
  .profile .info-rows > * {
    width: 100%;
  }

  .navbar {
    left: auto;

    .logo {
      display: none
    }

    .logo-mobile {
      display: block;
      position: fixed;
      left: 15px;
      top: 7px;
      z-index: 1000;
    }

    &.navbar-mobile {
      position: absolute;
      margin-bottom: 0;
      top: 0;
      right: 0;
      width: 173px;

      .navbar-nav {
        position: absolute;
        display: block;
        background-color: $light-grey;
        top: 54px;
        right: 0;

        .btn-group {
          display: inline-block;
        }

        li {
          width: 100%;
        }
      }


      .dropdown-toggle {
        float: none;
      }

      .dropdown-menu {
        position: static;
        max-height: 50vh;
        overflow-y: auto;
        overflow-x: hidden;
        float: none;
      }
    }

    .navbar-header {
      float: none;

      .navbar-toggle {
        position: relative;
        display: block;
        margin: 0;
        padding: 9px 10px;
        font-size: $font-size-text4;
        z-index: 1000;

        .glyphicon {
          margin-right: 5px;
        }
      }
    }

    .navbar-collapse.collapse {
      display: none !important;

      &.in {
        display: block !important;
      }
    }

    .navbar-right {
      margin: 0;
      float: none !important;

      li {
        float: none;
      }
    }
  }

  .navbar-mobile-fake-bar {
    display: block;
  }
}

@media (max-width: 1024px) {

  //Widths
  $sidebar-right-panel-width: 260px;
  $sidebar-left-panel-width: 190px;
  $sidebar-left-collapsed-width: 45px;
  .sidebar-left-expanded {
    width: 100%;
  }
  .sidebar-left-expanded,
  .sidebar-left-panel-save,
  .sidebar-left-panel-subscribe,
  .sidebar-left-panel-view-expanded {
    width: $sidebar-left-panel-width;
  }

  .sidebar-right {
    width: $sidebar-right-panel-width;
    margin-right: -($sidebar-right-panel-width);
  }

  .page-wrapper {

    .page-center-column {
      width: calc(100% - #{$sidebar-left-panel-width});
      margin-left: $sidebar-left-panel-width;
    }

    &.sidebar-right-toggled .page-center-column {
      width: calc(100% - #{$sidebar-left-panel-width} - #{$sidebar-right-panel-width});
    }

    &.sidebar-left-toggled {

      .page-center-column {
        width: calc(100% - #{$sidebar-left-collapsed-width});
        margin-left: $sidebar-left-collapsed-width;
      }

      .sidebar-left-expanded {
        margin-left: -($sidebar-left-panel-width);
      }
    }

    &.sidebar-left-panel-view-collapsed-toggled {

      .page-center-column {
        width: calc(100% - #{$sidebar-left-panel-width} - #{$sidebar-left-collapsed-width});
        margin-left: calc(#{$sidebar-left-panel-width} + #{$sidebar-left-collapsed-width});
      }

      .sidebar-left-panel-view-collapsed {
        left: $sidebar-left-panel-width;
      }

      &.sidebar-left-toggled {
        .page-center-column {
          width: calc(100% - 2 * #{$sidebar-left-collapsed-width});
          margin-left: calc(2 * #{$sidebar-left-collapsed-width});
        }

        .sidebar-left-panel-view-collapsed {
          left: $sidebar-left-collapsed-width;
        }
      }

      &.sidebar-right-toggled {
        .page-center-column {
          width: calc(100% - #{$sidebar-left-panel-width} - #{$sidebar-right-panel-width} - #{$sidebar-left-collapsed-width});
          margin-left: calc(#{$sidebar-left-panel-width} + #{$sidebar-left-collapsed-width});
        }
      }
    }

    &.sidebar-left-panel-view-expanded-toggled,
    &.sidebar-left-panel-subscribe-toggled,
    &.sidebar-left-panel-save-toggled {

      .page-center-column {
        width: calc(100% - 2 * #{$sidebar-left-panel-width});
        margin-left: calc(2 * #{$sidebar-left-panel-width});
      }

      .sidebar-left-panel-view-expanded,
      .sidebar-left-panel-subscribe,
      .sidebar-left-panel-save {
        left: $sidebar-left-panel-width;
      }

      &.sidebar-left-toggled {
        .page-center-column {
          width: calc(100% - #{$sidebar-left-panel-width} - #{$sidebar-left-collapsed-width});
          margin-left: calc(#{$sidebar-left-panel-width} + #{$sidebar-left-collapsed-width});
        }

        .sidebar-left-panel-view-expanded,
        .sidebar-left-panel-subscribe,
        .sidebar-left-panel-save {
          left: $sidebar-left-collapsed-width;
        }
      }

      &.sidebar-right-toggled {
        .page-center-column {
          width: calc(100% - 2 * #{$sidebar-left-panel-width} - #{$sidebar-right-panel-width});
          margin-left: calc(2 * #{$sidebar-left-panel-width});
        }
      }
    }

    &.sidebar-left-panel-subscribe-toggled,
    &.sidebar-left-panel-save-toggled {

      .page-center-column {
        width: calc(100% - 2 * #{$sidebar-left-panel-width});
        margin-left: calc(2 * #{$sidebar-left-panel-width});
      }

      &.sidebar-left-panel-view-expanded-toggled {

        .page-center-column {
          width: calc(100% - 3 * #{$sidebar-left-panel-width});
          margin-left: calc(3 * #{$sidebar-left-panel-width});
        }

        .sidebar-left-panel-view-expanded {
          left: calc(2 * #{$sidebar-left-panel-width});
        }

        &.sidebar-left-toggled {
          .page-center-column {
            width: calc(100% - 2 * #{$sidebar-left-panel-width} - #{$sidebar-left-collapsed-width});
            margin-left: calc(2 * #{$sidebar-left-panel-width} + #{$sidebar-left-collapsed-width});
          }

          .sidebar-left-panel-view-expanded {
            left: calc(#{$sidebar-left-panel-width} + #{$sidebar-left-collapsed-width});
          }
        }

        &.sidebar-right-toggled {

          .page-center-column {
            width: calc(100% - 3 * #{$sidebar-left-panel-width} - #{$sidebar-right-panel-width});
            margin-left: calc(3 * #{$sidebar-left-panel-width});
          }
        }
      }

      &.sidebar-left-panel-view-collapsed-toggled {

        .page-center-column {
          width: calc(100% - 2 * #{$sidebar-left-panel-width} - #{$sidebar-left-collapsed-width});
          margin-left: calc(2 * #{$sidebar-left-panel-width} + #{$sidebar-left-collapsed-width});
        }

        .sidebar-left-panel-view-collapsed {
          left: calc(2 * #{$sidebar-left-panel-width});
        }

        &.sidebar-left-toggled {
          .page-center-column {
            width: calc(100% - #{$sidebar-left-panel-width} - 2 * #{$sidebar-left-collapsed-width});
            margin-left: calc(#{$sidebar-left-panel-width} + 2 * #{$sidebar-left-collapsed-width});
          }

          .sidebar-left-panel-view-collapsed {
            left: calc(#{$sidebar-left-panel-width} + #{$sidebar-left-collapsed-width});
          }
        }

        &.sidebar-right-toggled {

          .page-center-column {
            width: calc(100% - 2 * #{$sidebar-left-panel-width} - #{$sidebar-right-panel-width} - #{$sidebar-left-collapsed-width});
            margin-left: calc(2 * #{$sidebar-left-panel-width} + #{$sidebar-left-collapsed-width});
          }
        }
      }
    }

    &.sidebar-right-toggled.sidebar-left-toggled {

      .page-center-column {
        width: calc(100% - #{$sidebar-right-panel-width} - #{$sidebar-left-collapsed-width});
        margin-left: $sidebar-left-collapsed-width;
      }

      .sidebar-left-expanded {
        margin-left: -($sidebar-left-panel-width);
      }

      &.sidebar-left-panel-view-collapsed-toggled .page-center-column {
        width: calc(100% - #{$sidebar-right-panel-width} - 2 * #{$sidebar-left-collapsed-width});
        margin-left: calc(2 * #{$sidebar-left-collapsed-width});
      }

      &.sidebar-left-panel-view-expanded-toggled,
      &.sidebar-left-panel-subscribe-toggled,
      &.sidebar-left-panel-save-toggled {
        .page-center-column {
          width: calc(100% - #{$sidebar-left-panel-width} - #{$sidebar-right-panel-width} - #{$sidebar-left-collapsed-width});
          margin-left: calc(#{$sidebar-left-panel-width} + #{$sidebar-left-collapsed-width});
        }
      }

      &.sidebar-left-panel-subscribe-toggled,
      &.sidebar-left-panel-save-toggled {

        &.sidebar-left-panel-view-expanded-toggled {
          .page-center-column {
            width: calc(100% - 3 * #{$sidebar-left-panel-width} - #{$sidebar-right-panel-width});
          }

          &.sidebar-left-toggled {
            .page-center-column {
              width: calc(100% - 2 * #{$sidebar-left-panel-width} - #{$sidebar-right-panel-width} - #{$sidebar-left-collapsed-width});
              margin-left: calc(2 * #{$sidebar-left-panel-width} + #{$sidebar-left-collapsed-width});
            }
          }
        }

        &.sidebar-left-panel-view-collapsed-toggled {
          .page-center-column {
            width: calc(100% - 2 * #{$sidebar-left-panel-width} - #{$sidebar-right-panel-width});
          }

          &.sidebar-left-toggled {
            .page-center-column {
              width: calc(100% - #{$sidebar-left-panel-width} - #{$sidebar-right-panel-width} - 2 * #{$sidebar-left-collapsed-width});
              margin-left: calc(#{$sidebar-left-panel-width} + 2 * #{$sidebar-left-collapsed-width});
            }
          }
        }
      }
    }

    .page-center-column.page-error div {
      width: $col-8;
      margin: 73px $col-2 0;
    }
  }

}

@media (max-width: 880px) {

  .col-checkbox {
    padding-top: 7px !important;
  }

  .comment-box .comment-replies {
    padding-left: 30px !important;
  }

  //Widths
  $sidebar-right-panel-width: 215px;
  $sidebar-left-panel-width: 165px;
  $sidebar-left-collapsed-width: 45px;
  .sidebar-left-expanded {
    max-width: 100%;
  }
  .sidebar-left-expanded,
  .sidebar-left-panel-save,
  .sidebar-left-panel-subscribe,
  .sidebar-left-panel-view-expanded {
    width: $sidebar-left-panel-width;
  }

  .sidebar-right {
    width: $sidebar-right-panel-width;
    margin-right: -($sidebar-right-panel-width);
  }

  .page-wrapper {

    .page-center-column {
      width: calc(100% - #{$sidebar-left-panel-width});
      margin-left: $sidebar-left-panel-width;
    }

    &.sidebar-right-toggled .page-center-column {
      width: calc(100% - #{$sidebar-left-panel-width} - #{$sidebar-right-panel-width});
    }

    &.sidebar-left-toggled {

      .page-center-column {
        width: calc(100% - #{$sidebar-left-collapsed-width});
        margin-left: $sidebar-left-collapsed-width;
      }

      .sidebar-left-expanded {
        margin-left: -($sidebar-left-panel-width);
      }
    }

    &.sidebar-left-panel-view-collapsed-toggled {

      .page-center-column {
        width: calc(100% - #{$sidebar-left-panel-width} - #{$sidebar-left-collapsed-width});
        margin-left: calc(#{$sidebar-left-panel-width} + #{$sidebar-left-collapsed-width});
      }

      .sidebar-left-panel-view-collapsed {
        left: $sidebar-left-panel-width;
      }

      &.sidebar-left-toggled {
        .page-center-column {
          width: calc(100% - 2 * #{$sidebar-left-collapsed-width});
          margin-left: calc(2 * #{$sidebar-left-collapsed-width});
        }

        .sidebar-left-panel-view-collapsed {
          left: $sidebar-left-collapsed-width;
        }
      }

      &.sidebar-right-toggled {
        .page-center-column {
          width: calc(100% - #{$sidebar-left-panel-width} - #{$sidebar-right-panel-width} - #{$sidebar-left-collapsed-width});
          margin-left: calc(#{$sidebar-left-panel-width} + #{$sidebar-left-collapsed-width});
        }
      }
    }

    &.sidebar-left-panel-view-expanded-toggled,
    &.sidebar-left-panel-subscribe-toggled,
    &.sidebar-left-panel-save-toggled {

      .page-center-column {
        width: calc(100% - 2 * #{$sidebar-left-panel-width});
        margin-left: calc(2 * #{$sidebar-left-panel-width});
      }

      .sidebar-left-panel-view-expanded,
      .sidebar-left-panel-subscribe,
      .sidebar-left-panel-save {
        left: $sidebar-left-panel-width;
      }

      &.sidebar-left-toggled {
        .page-center-column {
          width: calc(100% - #{$sidebar-left-panel-width} - #{$sidebar-left-collapsed-width});
          margin-left: calc(#{$sidebar-left-panel-width} + #{$sidebar-left-collapsed-width});
        }

        .sidebar-left-panel-view-expanded,
        .sidebar-left-panel-subscribe,
        .sidebar-left-panel-save {
          left: $sidebar-left-collapsed-width;
        }
      }

      &.sidebar-right-toggled {
        .page-center-column {
          width: calc(100% - 2 * #{$sidebar-left-panel-width} - #{$sidebar-right-panel-width});
          margin-left: calc(2 * #{$sidebar-left-panel-width});
        }
      }
    }

    &.sidebar-left-panel-subscribe-toggled,
    &.sidebar-left-panel-save-toggled {

      .page-center-column {
        width: calc(100% - 2 * #{$sidebar-left-panel-width});
        margin-left: calc(2 * #{$sidebar-left-panel-width});
      }

      &.sidebar-left-panel-view-expanded-toggled {

        .page-center-column {
          width: calc(100% - 3 * #{$sidebar-left-panel-width});
          margin-left: calc(3 * #{$sidebar-left-panel-width});
        }

        .sidebar-left-panel-view-expanded {
          left: calc(2 * #{$sidebar-left-panel-width});
        }

        &.sidebar-left-toggled {
          .page-center-column {
            width: calc(100% - 2 * #{$sidebar-left-panel-width} - #{$sidebar-left-collapsed-width});
            margin-left: calc(2 * #{$sidebar-left-panel-width} + #{$sidebar-left-collapsed-width});
          }

          .sidebar-left-panel-view-expanded {
            left: calc(#{$sidebar-left-panel-width} + #{$sidebar-left-collapsed-width});
          }
        }

        &.sidebar-right-toggled {

          .page-center-column {
            width: calc(100% - 3 * #{$sidebar-left-panel-width} - #{$sidebar-right-panel-width});
            margin-left: calc(3 * #{$sidebar-left-panel-width});
          }
        }
      }

      &.sidebar-left-panel-view-collapsed-toggled {

        .page-center-column {
          width: calc(100% - 2 * #{$sidebar-left-panel-width} - #{$sidebar-left-collapsed-width});
          margin-left: calc(2 * #{$sidebar-left-panel-width} + #{$sidebar-left-collapsed-width});
        }

        .sidebar-left-panel-view-collapsed {
          left: calc(2 * #{$sidebar-left-panel-width});
        }

        &.sidebar-left-toggled {
          .page-center-column {
            width: calc(100% - #{$sidebar-left-panel-width} - 2 * #{$sidebar-left-collapsed-width});
            margin-left: calc(#{$sidebar-left-panel-width} + 2 * #{$sidebar-left-collapsed-width});
          }

          .sidebar-left-panel-view-collapsed {
            left: calc(#{$sidebar-left-panel-width} + #{$sidebar-left-collapsed-width});
          }
        }

        &.sidebar-right-toggled {

          .page-center-column {
            width: calc(100% - 2 * #{$sidebar-left-panel-width} - #{$sidebar-right-panel-width} - #{$sidebar-left-collapsed-width});
            margin-left: calc(2 * #{$sidebar-left-panel-width} + #{$sidebar-left-collapsed-width});
          }
        }
      }
    }

    &.sidebar-right-toggled.sidebar-left-toggled {

      .page-center-column {
        width: calc(100% - #{$sidebar-right-panel-width} - #{$sidebar-left-collapsed-width});
        margin-left: $sidebar-left-collapsed-width;
      }

      .sidebar-left-expanded {
        margin-left: -($sidebar-left-panel-width);
      }

      &.sidebar-left-panel-view-collapsed-toggled .page-center-column {
        width: calc(100% - #{$sidebar-right-panel-width} - 2 * #{$sidebar-left-collapsed-width});
        margin-left: calc(2 * #{$sidebar-left-collapsed-width});
      }

      &.sidebar-left-panel-view-expanded-toggled,
      &.sidebar-left-panel-subscribe-toggled,
      &.sidebar-left-panel-save-toggled {
        .page-center-column {
          width: calc(100% - #{$sidebar-left-panel-width} - #{$sidebar-right-panel-width} - #{$sidebar-left-collapsed-width});
          margin-left: calc(#{$sidebar-left-panel-width} + #{$sidebar-left-collapsed-width});
        }
      }

      &.sidebar-left-panel-subscribe-toggled,
      &.sidebar-left-panel-save-toggled {

        &.sidebar-left-panel-view-expanded-toggled {
          .page-center-column {
            width: calc(100% - 3 * #{$sidebar-left-panel-width} - #{$sidebar-right-panel-width});
          }

          &.sidebar-left-toggled {
            .page-center-column {
              width: calc(100% - 2 * #{$sidebar-left-panel-width} - #{$sidebar-right-panel-width} - #{$sidebar-left-collapsed-width});
              margin-left: calc(2 * #{$sidebar-left-panel-width} + #{$sidebar-left-collapsed-width});
            }
          }
        }

        &.sidebar-left-panel-view-collapsed-toggled {
          .page-center-column {
            width: calc(100% - 2 * #{$sidebar-left-panel-width} - #{$sidebar-right-panel-width});
          }

          &.sidebar-left-toggled {
            .page-center-column {
              width: calc(100% - #{$sidebar-left-panel-width} - #{$sidebar-right-panel-width} - 2 * #{$sidebar-left-collapsed-width});
              margin-left: calc(#{$sidebar-left-panel-width} + 2 * #{$sidebar-left-collapsed-width});
            }
          }
        }
      }
    }
  }
}

@media (max-width: 540px) {

  .alerts-global {
    left: calc(50% - 150px);
    width: 300px;

    .alert {
      width: 300px;
    }
  }

  .cover-bg-container .panel-centered-wrapper .panel-centered {
    width: 340px;
    min-height: 340px;
    padding: 25px;
  }
  .sidebar-left-expanded {
    max-width: 100%;
  }
  .sidebar-left-expanded,
  .sidebar-left-collapsed,
  .sidebar-left-panel-view-collapsed,
  .sidebar-left-panel-view-expanded,
  .sidebar-left-panel-save,
  .sidebar-left-panel-subscribe {
    position: absolute;
  }

  .page-wrapper .page-center-column.page-error div {
    width: $col-10;
    margin: 40px $col-1 0;

    h1 {
      margin-top: 30px;
      font-size: $font-size-text12;
    }

    p {
      font-size: $font-size-text5;
    }
  }

  .comment-box .comment-replies {
    padding-left: 0 !important;
  }
}
