@import "../../variables";

.login-page {
  &.cover-bg-container {
    background-image: url("/img/tg-background-gradient.svg");
  }

  .panel-centered {

    &.form-style-labels-inside-inputs .form-group {
      margin: 6px 0;

      label {
        padding: 12px 0 12px 16px;
      }

      .form-control {
        padding: 12px 16px 12px 0;
      }
    }

    .forgot-password-link {
      @include font-normal();
      font-size: $font-size-text5;
      float: right;
      color: $secondary;
      font-weight: 700;

      @media (max-width: 1280px) {
        font-size: $font-size-text5;
      }

      @media (max-width: 1024px) {
        font-size: $font-size-text3;
      }

      @media (max-width: 880px) {
        font-size: $font-size-text1;
      }
    }

    .checkbox {
      color: $lighter-black;

      @media (max-width: 720px) {
        margin-top: 18px;
      }

      input[type=checkbox]:not(old) {

        & + label {
          & > span {
            margin-right: 10px;
            border-radius: 0;
            background-color: transparent;
            border: 1px solid $lighter-black;
          }
          & > span:before {
            color: $primary
          }
        }
      }
    }

    .panel-centered-actions {
      width: 100%;
      padding-top: 14px;
      text-align: center;

      .btn-primary {
        margin-bottom: 16px;
      }
    }
  }

}
