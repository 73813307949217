@import "variables";

.handbook-container {
  padding: 15px;
  box-shadow: 0 0 10px $grey;
  background-color: $white;

  .content-title {
    margin-bottom: 35px;
  }

  .content-subtitle {
    font-size: 1.5rem;
    margin-top: 15px;
  }
}

.handbook-info {
  padding: 0 5px;

  &.sticky {
    position: sticky;
    top: 20px;
    overflow-y: auto;
    max-height: calc(94vh - 41px);
  }

  .handbook-navigation {
    > div {
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
      margin-bottom: 10px;

      &.H2 {
        margin-left: 5px;
      }

      &.H3 {
        margin-left: 10px;
      }

      &.H4 {
        margin-left: 15px;
      }

      &.H5 {
        margin-left: 20px;
      }

      &.A {
        margin-left: 10px;
      }
    }
  }
}

.handbook-content {
  padding: 0 15px 15px;

  img {
    max-width: 100%;
    height: auto;
  }

  dl, ul, ol {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  ul {
    list-style: disc;

    ul {
      list-style: circle;
    }
  }

  ol {
    list-style: decimal;
  }

  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-weight: 700;
    line-height: 1.1;
    margin: 1rem 0 0.5rem;
    transition: 0.3s;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.75rem;
  }

  h3 {
    font-size: 1.5rem;

    strong {
      font-size: 24px;
      font-weight: bold;
    }
  }

  h4 {
    font-size: 1.25rem;
  }

  h5 {
    font-size: 1rem;
  }

  hr {
    border-top: 1px solid #e5e5e5;
  }

  p, dl, ul, ol {
    font-size: 16px;
    line-height: 24px;
    overflow-wrap: break-word;
  }

  p {
    margin-bottom: 0.75rem;
    line-height: 24px;
  }

  blockquote {
    pre.code.json {
      background-color: #293B4B;
      padding: 20px;
      border-radius: 10px;
      width: 40%;
      color: #28B3A7;
    }

    overflow-x: auto;
  }

  pre {
    border: 1px solid $grey;
    border-radius: 6px;
    background-color: $light-grey;
    padding: 10px;
  }

  table {
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    font-size: 0.875rem;

    thead th {
      font-weight: 700;
      vertical-align: bottom;
      border-bottom: 2px solid;
    }

    th, td {
      font-size: 16px;
      padding: 0.75rem;
    }
  }

  a {
    font-size: 16px;
  }
}

.welcome-page {
  margin-top: 20px;

  h1 {
    font-size: 2.5rem !important;
    font-weight: bold;
    margin-bottom: 10px !important;

  }

  ul {
    padding-left: 12px;

    li {
      &:before {
        content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
        color: $primary; /* Change the color */
        font-weight: bold; /* If you want it to be bold */
        display: inline-block; /* Needed to add space between the bullet and the text */
        width: 1em; /* Also needed for space (tweak if needed) */
        margin-left: -1em; /* Also needed for space (tweak if needed) */
      }
    }
  }

  a {
    font-weight: bold;
  }

  .evo-card {
    line-height: 20px;
    padding: 15px 30px;
    margin: 0 15px
  }

  .underline {
    height: 3px;
    width: 100%;
    max-width: 125px;
    background-color: $primary;
    margin: auto auto 25px;
  }
}

.evo-card {
  border-radius: 15px;
  box-shadow: 10px 10px 50px 0 rgba(0, 0, 0, 0.1);
}
