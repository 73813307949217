@mixin layout-table-cell {
  display: table-cell;
  vertical-align: middle;
}

@mixin form-group {
  .form-control {
    padding: 8px 9px;
    color: $very-dark-grey;
    background-color: $lighter-grey;
  }

  input.form-control:focus,
  textarea.form-control:focus {
    padding-left: 2px;
  }

  select.form-control {
    padding-left: 7px;
    padding-right: 25px;
    -webkit-appearance: none; /*Removes default chrome and safari style*/
    -moz-appearance: none; /* Removes Default Firefox style*/
    background: $lighter-grey url('../img/select-arrow-green.png') no-repeat; /*Adds background-image*/
    background-position: right 9px center; /*Position of the background-image*/
    text-indent: 0.01px; /* Removes default arrow from firefox*/
    text-overflow: ""; /*Removes default arrow from firefox*/

    /*Removes default arrow from IE*/
    &::-ms-expand {
      display: none;
    }
  }
}

@mixin form-style-bordered-inputs-form-control {
  padding: 4px 9px !important;
  line-height: 32px;

  &:focus {
    padding: 4px 9px !important;
  }

  &.ui-select {
    padding: 0 !important;

    &:focus {
      padding: 0 !important;
    }
  }

  .ui-select-match > .btn,
  .ui-select-match > .btn:hover,
  .ui-select-match > .btn-default,
  .ui-select-match > .btn-default:hover {
    padding: 4px !important;
    font-size: $font-size-text3;
  }
}

@mixin media-font-size($lg, $md, $sm) {
  @media (max-width: 1280px) {
    font-size: $lg;
  }

  @media (max-width: 1024px) {
    font-size: $md;
  }

  @media (max-width: 880px) {
    font-size: $sm;
  }
}

@mixin icon-size($size, $default, $lg, $md, $sm) {
  .icon-size-#{$size} {
    font-size: $default;

    @include media-font-size($lg, $md, $sm);
  }
}

@mixin font-normal() {
  font-family: 'Inter', sans-serif;
}

@mixin font-medium() {
  font-family: 'Inter', sans-serif;
}

@mixin font-bold() {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
}

@mixin font-black() {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
}

@mixin sidebar-level($spacing, $lvl) {
  @if $lvl == 0 {
    .lvl-#{$lvl} {
      padding-left: $spacing + px !important;

      > .list-item-dot {
        left: $spacing + px !important;
      }
    }
  } @else {
    .lvl-#{$lvl} {
      padding-left: ($lvl) * $spacing + px !important;

      > .list-item-dot {
        left: ($lvl) * $spacing + px !important;
      }
    }
  }
}

@mixin button-style($bg-color, $text-color) {
  background-color: $bg-color;
  color: $text-color;
  border: 1px solid $bg-color;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
}

@mixin make-col-with-deduction($size, $columns: $grid-columns, $deduction: 50px) {
  //@include make-col($size, $columns);
  $max-width: percentage($size / $columns);
  max-width: calc(#{$max-width} - #{$deduction})
}
