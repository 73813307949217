@import "../../variables";

.system-ssrs-fetch-page {

  .actions .btn {
    margin-top: 28px;
  }

  .modal-dialog {
    width: 435px;

    .form-group {

      &.no-background {
        margin-bottom: 17px !important;

        label {
          width: 135px;
          margin: 0 !important;
          padding: 0 !important;
        }

        .form-control {
          width: 110px;
          margin: 0 !important;
          margin-top: -1px !important;
          padding: 0 !important;
          padding-right: 25px!important;
        }
      }
    }
  }
}