@import "variables";
@import "mixins";
@import "~bootstrap/scss/mixins/buttons";

label {
  font-weight: normal;

  &.nowrap {
    white-space: nowrap;
  }
}

hr {
  border-top: 1px solid $darker-grey;
}

.text-danger {
  @include font-normal();
  font-size: $font-size-text5;
  text-transform: uppercase;
  color: $tg-light-blue !important;

  img {
    margin-top: -4px;
  }
}

.btn {
  padding: 14px 27px;
  @include font-normal();
  font-size: $font-size-text5;
  line-height: $font-size-text5;
  white-space: nowrap;
  border-radius: 3px;
  margin-top: 3px;
  margin-bottom: 3px;

  @media (max-width: 1280px) {
    font-size: $font-size-text4;
    line-height: $font-size-text4;

    .glyphicon {
      font-size: $font-size-text2;
      line-height: $font-size-text2;
    }
  }

  @media (max-width: 1024px) {
    font-size: $font-size-text3;
    line-height: $font-size-text3;

    .glyphicon {
      font-size: $font-size-text1;
      line-height: $font-size-text1;
    }
  }

  @media (max-width: 880px) {
    font-size: $font-size-text1;
    line-height: $font-size-text1;

    .glyphicon {
      font-size: $font-size-text0;
      line-height: $font-size-text0;
    }
  }

  &:focus {
    outline: 0;
  }
}

.btn-square {
  border-radius: 3px !important;
}

.btn-default {
  color: $very-dark-grey;
  background-color: $grey;

  &:hover {
    background-color: $lighter-grey;
  }
}

.btn-primary {
  @include button-variant($primary, $primary, lighten($primary, 7.5%), lighten($primary, 10%), lighten($primary, 10%), lighten($primary, 12.5%));

  &:hover, &:focus, &:not(:disabled):not(.disabled):active {
    color: $white;
  }
}

.btn-danger {
  @include button-variant($red, $red, lighten($red, 7.5%), lighten($red, 10%), lighten($red, 10%), lighten($red, 12.5%))
}

.btn-sm {
  padding: 6px 17px;

  @media (max-width: 1280px) {
    padding: 5px 15px;
  }
}

.btn-xs {
  padding: 5px 12px 6px;

  @media (max-width: 1280px) {
    padding: 7px 10px;
  }
}

.btn-group .dropdown-toggle {
  box-shadow: none !important;
}

.form-control {
  position: relative;
  height: auto;
  padding: 13px 25px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  font-size: 14px;

  @media (max-width: 1280px) {
    font-size: $font-size-text3;
  }

  @media (max-width: 1024px) {
    font-size: $font-size-text2;
  }

  @media (max-width: 880px) {
    font-size: $font-size-text0;
  }
}

.has-error .form-control:focus {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

input.form-control,
textarea.form-control,
select.form-control {
  margin-bottom: 14px;
  border-radius: 0;
  color: $primary;

  &::-webkit-input-placeholder {
    /* WebKit browsers */
    color: $primary;
  }

  &:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: $primary;
    opacity: 1;
  }

  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: $primary;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: $primary;
  }
}

input.form-control:focus,
textarea.form-control:focus {
  z-index: 2;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  border-left: 7px solid $primary;
  padding-left: 18px;
}

.add-on .input-group-btn .btn {
  padding: 12px 14px 11px;
  border: none;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;

  &:hover {
    background-color: $white;
  }

  .glyphicon-search {
    color: $primary;
  }
}

.input-group {
  .datepicker-input {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .input-group-btn {
    vertical-align: top;

    &.input-group-btn-separate .btn {
      margin-left: 5px;
    }

    .btn {
      padding: 10px 15px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 30px;
      border-top-right-radius: 30px;
      font-size: 20px;

      @media (max-width: 1024px) {
        padding: 10px 16px 11px;
      }

      &.btn-xs {
        @extend .btn-xs;
      }

      .glyphicon {
        top: 2px;
      }
    }
  }

  .checkbox {
    display: table-cell;
    width: 1%;
    padding: 4px 0 0 15px !important;
    vertical-align: top;
    white-space: nowrap;
  }
}

.navbar {
  border-radius: $default-border-radius;
  height: 64px !important;
  margin: 20px;

  &.navbar-default {
    background-color: $tg-light-background;
    border-bottom: 1px solid $grey;
  }

  .nav > li > a {
    padding-right: 13px;
    padding-left: 13px;
    color: $primary;
  }

  .navbar-toggle {
    margin-top: 12px;
    border: none;

    &:hover,
    &:active,
    &:visited,
    &:focus {
      background-color: $lighter-grey;
      outline: none;
    }

    .icon-bar {
      background-color: $very-dark-grey;
    }
  }

  .btn-group {
    display: inline-block;
  }

  .navbar-nav {
    float: left;
    margin: 0;
    flex-direction: row;

    & > li {
      margin-left: 14px;
      padding-top: 14px;
      padding-bottom: 14px;
      float: left;
      display: inherit;

      & > a,
      .btn-group .dropdown-toggle {
        margin: 0;
        padding: 6px 12px;
        @include font-normal();
        font-size: $font-size-text5;
        color: $primary;
        cursor: pointer;
        line-height: 23px;
      }
    }

    & > .open > a,
    & > .open > a:focus,
    & > .open > a:hover {
      background-color: $light-grey;
    }

    .dropdown-menu {
      max-height: 380px;
      width: 285px;
      padding: 0;
      margin-top: 11px;
      overflow-y: auto;
      border-radius: 0;
      -webkit-box-shadow: none;
      box-shadow: none;

      li {
        min-width: 265px;
        border-bottom: 1px solid #dcdce3;
        background-color: $tg-light-background;

        & > a {
          padding: 10px 20px;
          @include font-bold();
          font-size: $font-size-text5;
          display: block;

          &:hover {
            background-color: $grey;
          }

          img {
            margin-top: -2px;
            margin-right: 19px;
          }
        }
      }
    }
  }

  .navbar-left {
    float: left !important;
  }

  .navbar-right {
    float: right;
    margin-right: -8px;

    & ~ .navbar-right {
      margin-right: 0;
    }
  }

  .navbar-text {
    margin-left: 11px;
    margin-right: 11px;
    padding-top: 2px;
    @include font-black();
    font-size: $font-size-text7;
    color: $primary;
    cursor: default;
  }
}

@media (max-width: 470px) {
  .navbar-username {
    display: none;
  }
}

.table {
  margin-bottom: 10px;
  @include font-medium();

  & > thead {
    font-weight: bold;

    & > tr > th {
      @include font-black();
      white-space: nowrap;

      a {
        color: $very-dark-grey;
      }
    }
  }

  & > tbody + tbody {
    border-top: 0px solid $lighter-grey;
  }

  & > tbody > tr > td,
  & > tbody > tr > th,
  & > tfoot > tr > td,
  & > tfoot > tr > th,
  & > thead > tr > td,
  & > thead > tr > th {
    padding: 10px 10px 8px;
    border: none;
    border-bottom: 1px solid $grey;
    line-height: 1.42857143;

    &:first-child {
      border-left: none;
    }

    &:last-child {
      border-right: none;
    }

    &.selected {
      background-color: $light-grey;
    }

    .checkbox {
      margin-top: -4px;

      @media (max-width: 1024px) {
        margin-top: -2px;
      }

      @media (max-width: 880px) {
        margin-top: -1px;
      }
    }
  }

  tr {
    background-color: $white;

    &.selected {
      background-color: $light-grey;
    }

    td {
      max-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    a {
      font-size: $font-size-text5;
    }
  }

  &.table-striped {
    & > tbody > tr:nth-of-type(odd) {
      background-color: $light-grey;
    }
  }
}

.modal {
  .modal-dialog-wide {
    min-width: 50vw;
  }

  .modal-content {
    border-radius: 0;

    .modal-header {
      padding: 17px 30px 13px;
      border-bottom: none;
      background-color: $light-grey;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;

      .close {
        opacity: 1;
      }

      h4 {
        @include font-normal();
        font-size: $font-size-text5;
        color: $very-dark-grey;

        img {
          margin-top: -3px;
          margin-right: 5px;
        }
      }
    }

    .modal-body {
      padding: 16px 30px 11px;
    }

    .modal-footer {
      padding: 0 30px 19px;
      border-top: none;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;

      &.modal-footer-border-bottom {
        border-bottom: 50px solid $light-grey;
      }

      &.text-center {
        text-align: center !important;
      }
    }
  }
}

.badge-important {
  background-color: $red;
  color: $white;
}

div.dropup {
  display: inline-block;

  .dropdown-menu {
    margin: 0;
    padding: 0;
    border-color: $grey;
    border-radius: 0;
    box-shadow: none;

    li > a {
      display: block;
      color: $very-dark-grey;
      padding: 3px 20px;

      &:hover {
        background-color: $light-grey;
      }
    }
  }
}

/**
 * Fix the button position on calendar
 */
spx-date-picker .btn {
  margin-top: 0 !important;
}


.alert-light-warning {
  background-color: #e6eaf1;
  border: 1px solid #2533431f;
  border-radius: 4px;
  padding: 16px;
  color: $black;
  font-weight: 600;
  font-size: $font-size-text4;


  & > i {
    font-size: 24px;
    color: #BD8307;
    margin-right: 8px;
  }
}
