@import '../theme/variables';
/*DASHBOARDS*/

div.dc-chart {
  float: left;
}

.dc-chart.new-line, #paging.new-line {
  clear: both;
  display: block;
}

.chart {
  font-size: 11px;
}

.amcharts-trend-line-t1 {
  stroke-linecap: round;
  stroke-dasharray: 5px 10px;
  -webkit-animation: css-effect 1s linear infinite;
  /*animation: css-effect 1s linear infinite;*/
}

select::-ms-expand {
  width: 12px;
  border: none;
  background: $white;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-30 {
  margin-top: 30px;
}

.first-line-header .handsontable tr:nth-child(1) td {
  background-color: $light-grey;
  border-bottom: 2px solid $grey3;
}

.amcharts-export-menu .export-main > a, .amcharts-export-menu .export-drawing > a, .amcharts-export-menu .export-delayed-capturing > a {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAPCAYAAAAGRPQsAAAAYElEQVQ4y2MoWnOTAQ/+jwXjVM8wcg2TLlpzc2/RmptncBh2BiovTazLzIrW3PyAw7APUHmSvInNQJwGERNmyAbiNYgYw2AGPiRkEMwwYmKKmZgYJ9YwhgE3jCI8eA0DALQYqCk/haIIAAAAAElFTkSuQmCC') !important;
}

.amcharts-export-menu .export-main:hover > a {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAPCAYAAAAGRPQsAAAAYElEQVQ4y2MoWnOTAQ/+jwXjVM8wcg2TLlpzc2/RmptncBh2BiovTazLzIrW3PyAw7APUHmSvInNQJwGERNmyAbiNYgYw2AGPiRkEMwwYmKKmZgYJ9YwhgE3jCI8eA0DALQYqCk/haIIAAAAAElFTkSuQmCC') !important;
}

.swatch {
  background: $black none repeat scroll 0 0;
  border-radius: 50%;
  display: block;
  float: left;
  height: 1.1em;
  margin: 0 1em 1em 0;
  overflow: hidden;
  width: 1.1em;
  cursor: pointer;
  text-align: center;
  opacity: 0.3;
}

.swatchRandom {
  cursor: pointer;
  position: relative;
  top: -2px;
}

.swatch:hover {
  opacity: 1;
}

.selectedColor {
  border: 1px solid $black;
  opacity: 1;
}

.ui-select-dropdown {
  max-height: 500px !important;
}

#chartLegendDiv {
  cursor: pointer;
}

.amcharts-guide {
  cursor: pointer;
}

.btn-chart {
  font-size: 8px !important;
  padding: 5px 5px !important;
}


/* DashBoard */
.dc-chart g.row text {
  fill: $light-black;
  text-anchor: end !important;

}

.ui-select {
  border: 1px solid $grey4;
}

.ui-select .btn {
  border-radius: 0;
}
