
  .wave-ui {
    &-large-title {
      color: #253343 !important;
      font-size: 26px !important;
      line-height: 32px !important;
      font-weight: 400 !important;
    }

      &-title-one {
        color: #253343 !important;
        font-size: 22px !important;
        line-height: 26px !important;
        font-weight: 400 !important;
      }

      &-title-two {
        color: #253343 !important;
        font-size: 17px !important;
        line-height: 22px !important;
        font-weight: 400 !important;
      }

      &-title-three {
        color: #253343 !important;
        font-size: 15px !important;
        line-height: 20px !important;
        font-weight: 700 !important;
      }

      &-headline {
        color: #253343 !important;
        font-size: 13px !important;
        line-height: 16px !important;
        font-weight: 600 !important;
      }

      &-body {
        color: #253343 !important;
        font-size: 13px !important;
        line-height: 16px !important;
        font-weight: 500 !important;
      }

      &-callout {
        color: #253343 !important;
        font-size: 12px !important;
        line-height: 15px !important;
        font-weight: 400 !important;
      }

      &-subheadline {
        color: #253343 !important;
        font-size: 11px !important;
        line-height: 14px !important;
        font-weight: 400 !important;
      }

      &-footnote {
        color: #253343 !important;
        font-size: 10px !important;
        line-height: 13px !important;
        font-weight: 400 !important;
      }

      &-caption-one {
        color: #253343 !important;
        font-size: 10px !important;
        line-height: 13px !important;
        font-weight: 400 !important;
      }

      &-caption-two {
        color: #253343 !important;
        font-size: 10px !important;
        line-height: 13px !important;
        font-weight: 500 !important;
      }

      &-positive-default {
        color: #253343 !important;
      }

      &-positive-inactive {
        color: #253343E5 !important;
      }

      &-positive-dimmed {
        color: #25334399 !important;
      }

      &-positive-disabled {
        color: #25334399 !important;
      }

      &-negative-default {
        color: #FBFCFD !important;
      }

      &-color-primary {
        color: #0161bf !important;
      }
    }
