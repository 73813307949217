@import '../theme/variables';

.dynamic-font {
  font-family: $font-family-sans-serif-medium;
  font-size: 14px !important;
  color: $very-dark-grey !important;
}

@media (max-width: 1280px) {
  .dynamic-font {
    font-size: 13px !important;
  }

  .form-group {
    .dynamic-font {
      font-size: 12px !important;
    }
  }

  .profile {
    .info-rows {
      > * {
        width: 320px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .dynamic-font {
    font-size: 12px !important;
  }

  .form-group {
    .dynamic-font {
      font-size: 11px !important;
    }
  }

  .profile {
    .info-rows {
      > * {
        width: 290px;
      }
    }
  }
}

@media (max-width: 880px) {
  .dynamic-font {
    font-size: 10px !important;
  }

  .form-group {
    .dynamic-font {
      font-size: 9px !important;
    }
  }

  .profile {
    .info-rows {
      > * {
        width: 100%;
        min-width: 290px;
      }
    }
  }
}
