@import "../theme/variables";

.toast-top-center {
  top: 25px;
}

body {
  line-height: 1.1;
}

.impersonating-users-button {
  top: 38px !important;
}


.section-extra-margin-top .form-group {
  margin-bottom: 0;
}

.theme_1 .form-group.inline {
  display: flex;
  align-items: center;
}

.theme_1 .sidebar-left-card .form-group.inline {
  background-color: $lighter-grey;
  padding: 8px 0 8px 9px;
  margin-bottom: 5px;
}

.theme_1 .sidebar-left-card .form-group.inline label {
  margin: 0;
}

.theme_1 .sidebar-left-card .form-group.inline select.form-control {
  margin: 0;
  padding-bottom: 0;
  padding-top: 0;
}

.mb-8 {
  margin-bottom: 8px;
}

.theme_1 .form-style-labels-inside-inputs-condensed .form-group label {
  padding: 8px 10px;
}

.modal {
  overflow-y: auto;
}

.modal .modal-title .icon {
  padding-right: 5px;
}

.word-break {
  white-space: normal;
  word-break: break-word;
}

.sketch-picker {
  width: 200px;
}

.pre-wrap {
  white-space: pre-wrap;
}

.pointer {
  cursor: pointer;
}


.theme_1 .loading-bar-overlay #loading-bar div {
  padding-right: 0 !important;
  width: 25%;
  -webkit-transition: width ease 0.4s;

  transition: width ease 0.4s;
}

.theme_1 .col-10 {
  width: 10%;
}

a.disabled {
  pointer-events: none;
  cursor: not-allowed;
}

.theme_1 .loading-bar-overlay #loading-bar:before {
  content: attr(data-translation-loading);
}

.theme_1 .col-15 {
  width: 15%;
}

.actions-footer-bar .click-disabled {
  text-decoration: line-through !important;
}

.theme_1 .form-group.no-background select.form-control {
  padding-right: 25px;
}

.page-error {
  padding-top: 20vh;
}

.col-px-360 {
  min-width: 360px;
}

tr[valign="top"] > td {
  height: auto !important;
}

a {
  cursor: pointer;
}

.form-collection-row {
  position: relative;

  .collection-remove-button {
    //position: absolute;
    top: 0;
    right: 0;
  }
}

// for angularUI
.nav, .pagination, .carousel, .card-title a {
  cursor: pointer;
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

.theme_1 .form-style-labels-inside-inputs .form-group.has-error .focus-highlight {
  visibility: hidden !important;
}

.theme_1 .form-style-labels-inside-inputs-gray .form-group.has-error .focus-highlight {
  visibility: hidden !important;
}

.theme_1 .form-style-labels-inside-inputs-dark .form-group.has-error .focus-highlight {
  visibility: hidden !important;
}

.uploader-trigger {
  &.disabled {
    display: none;
  }

  .uploader-trigger-button {
    height: 32px;
    width: 32px;
  }
}


.theme_1 {

  .files-gallery {
    .uploaded-file-wrapper {
      position: relative;

      img {
        max-height: 75px;
        max-width: 100%;
      }

      .uploaded-file-remove {
        position: absolute;
        top: 0;
        right: 0;
        color: $red !important;
        font-size: 20px;

        &:hover {
          font-weight: bold;
        }
      }
    }
  }

  .row-expansion-body {
    .scrollable-list {
      overflow-y: auto;
      max-height: 130px;
    }
  }

  .growl-container.growl-fixed {
    position: fixed;
    top: 2vw !important;
    left: calc(50vw - 10%);
    //float: none;
  }

  .modal-md {
    width: 50vw;
  }

  .modal-lg {
    width: 60vw;
  }

  .main-page-title {
    font-size: 22px;
    font-weight: bold;
  }

  .main-page-subtitle {
    font-size: 20px;
    font-style: italic;
  }

  div.content-title {
    margin-top: 15px;
    font-size: 18px;
    font-weight: bold;
  }

  .content-title-container {
    padding: 30px;
    text-align: center;
    background: $primary;
    height: 300px;

    h2 {
      font-size: 36px;
      color: $white;
      font-weight: 900;
      text-transform: uppercase;
    }
  }

  .content-subtitle {
    font-size: 16px;
    font-style: italic;
    margin-bottom: 10px;
  }

  .color-marker-big {
    width: 20px !important;
    height: 20px !important;;
    border-radius: 25px !important;;
    margin-right: 4px !important;;
  }
}


.blink {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.theme_1 {
  .admin-help-box-visible {
    .help-target {
      border: 1px solid $red;
    }
  }

  [help-target] {
    cursor: pointer;
  }

  ul[ui-sortable] {
    li {
      //margin: 5px !important;
      //background-color: limegreen !important;
      cursor: pointer;
    }
  }

}

.admin-help-box {
  ol {
    list-style: decimal;
  }

  .admin-help-title {

  }

  .admin-help-body {

  }

  .admin-help-footer {

  }
}


.help-target-active {
  animation: blinker 1s linear infinite;
}

.typeahead .dropdown-item {
  display: block;
  background: transparent;
  outline: none;
  border: none;
  color: $primary;
  font-size: 14px;
  padding: 3px 20px;
  font-weight: 400;
  line-height: 20px;
  width: 100%;
  text-align: left;
}

.typeahead .dropdown-item:hover {
  background: $primary;
  color: $white;
}

.typeahead .ngb-highlight {
  font-family: $font-family-sans-serif-heavy;
  font-weight: 900;
}

.typeahead ul.selected-items li {
  display: inline-block;
  margin: 0 4px 7px 0;
  padding: 2px 8px 1px 12px;
  border: 1px solid $grey;
  border-radius: 10px;
}

.team-list {
  display: flex;
  flex-wrap: wrap;

  .person {
    width: 290px;
    height: 130px;
    border: 1px $grey solid;
    padding: 15px;
    margin: 0 15px 15px 0;
    display: flex;
    cursor: pointer;
    border-radius: 3px;
    position: relative;

    &.birthday {
      border-color: #ff7e42
    }

    .avatar {
      display: block;
      width: 90px;
      height: 90px;
      min-width: 90px;
      min-height: 90px;
      margin-right: 20px;

      > img {
        border-radius: 70px;
        width: 100%;
        height: 100%;
        box-shadow: $light-green 0 4px 10px -6px;
      }
    }
  }
}

.profile {
  margin-top: 15px;

  .header {
    margin-bottom: 15px;

    .avatar {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;

      img {
        width: 150px;
        height: 150px;
        border-radius: 100px;
        box-shadow: $primary 0 4px 10px -4px;
      }
    }

    .description {
      h1 {
        text-transform: none;
        font-weight: bold;
        font-size: 18px;
        margin-top: 0;
      }
    }

  }

  .info-row {
    .ion {
      cursor: pointer;
    }

    .info-label {
      font-weight: 600;
      margin-bottom: 15px;
      border-bottom: 1px solid $grey;
      font-size: 18px;
    }

    .item-row {
      margin-bottom: 5px;
    }

    .info-items {
      > * {
        margin-bottom: 5px;
      }
    }
  }

  .item-label {
    font-weight: 600;
    margin-bottom: 10px;
    padding-right: 5px;
  }

  .info-rows {
    display: flex;
    flex-wrap: wrap;

    > * {
      width: 410px;
      margin: 0 15px 15px 0;
      padding: 10px;
      border: 1px solid $grey;
      border-radius: 3px;
    }

  }
}

.theme_1 timepicker .btn {
  padding: inherit;
}

.bg-toolbar-container {
  bottom: 60px !important;
}
