@import "../../variables";

.system-messages-page {

  .form-group label {
    width: 10%;
  }

  .actions-footer-bar {
    margin-top: 18px;
  }
}