//Colors
$white: #FBFCFD;
$light-grey: #f4f4f4;
$lighter-grey: #e6e5e5;
$grey: #e1e1e1;
$grey2: #e3e3e2;
$grey3: #ccc;
$darker-grey: #c5c5c5;
$dark-grey: #8a8b8d;
$dark-grey-2: #4e4e4e;
$very-dark-grey: #454545;
$light-green: #3dcdc2;
$green: #34b2a7;
$dark-green: #199e94;
$light-blue: #506372;
$lighter-blue: #3b5265;
$blue: #324759;
$dark-blue: #293b4b;
$light-red: #fe6d6d;
$red: #ff5959;
$light-black: #333;
$black: #000;
$tg-main-text: rgba(37,51,67,.8);
$tg-blue: #1F8EF9;
$tg-dark-blue: #007CF3;
$tg-light-blue: #379CFE;
$tg-purple: #635dff;
$tg-light-background: #EFF1F6;
$tg-background: #e9ecf2;
$lighter-black: #65676e;



// new added colors from common.scss
$dark-purple: #736B8A;
$grey4: #eee;
$darker-grey2: #acacac;
$darker-grey3: #c3c3c3;
$lighter-grey2: #d5d5d5;
$dark-red: #a94442;


//Fonts
$font-family-sans-serif-regular: 'Inter', sans-serif;
$font-family-sans-serif-medium: 'Inter', sans-serif;
$font-family-sans-serif-semibold: 'Inter', sans-serif;
$font-family-sans-serif-heavy: 'Inter', sans-serif;

//Text size
$font-size-text0: 9px;
$font-size-text1: 10px;
$font-size-text2: 11px;
$font-size-text3: 12px;
$font-size-text4: 13px;
$font-size-text5: 14px;
$font-size-text6: 15px;
$font-size-text7: 16px;
$font-size-text8: 17px;
$font-size-text9: 18px;
$font-size-text10: 20px;
$font-size-text11: 22px;
$font-size-text12: 24px;
$font-size-text13: 26px;
$font-size-text14: 30px;


$sidebar-spacing: 20;
$default-border-radius: 10px;

$component-active-bg: $tg-blue;

$primary: rgb(1 97 191/1); //$tg-blue;
$secondary: $tg-purple;

$sidebar-selected-background-element: rgb(1 97 191/1);
$sidebar-hover-background-element: #015bb4;;
