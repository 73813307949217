@import "../../variables";

.forgot-password-page {
  &.cover-bg-container {
    background-image: url("/img/tg-background-gradient.svg");
  }

  .panel-centered {
    padding-bottom: 34px;

    .panel-centered-logo {
      margin-bottom: 29px;
    }

    &.form-style-labels-inside-inputs .form-group {
      margin: 6px 0;
      display: flex;
      flex-wrap: wrap;
      background-color: transparent;

      label {
        display: block;
        padding: 12px 0 12px 16px;
        flex-basis: 50%;
        line-height: 22px;
        background-color: $white;
        margin-bottom: 0px;
      }

      .form-control {
        display: block;
        padding: 12px 16px 12px 0;
        flex-basis: 50%;
        background-color: $white;
      }

      &.has-error {
        margin-bottom: 6px !important;
      }

      .help-block {
        position: relative !important;
        margin-bottom: 0 !important;
        display: block;
        flex-basis: 100%;
        min-width: 100%;
        flex-grow: 1;
      }
    }

    p {
      margin: 10px 0;
      font-family: 'Inter', sans-serif;
      font-size: $font-size-text6;
      color: $lighter-black;

      @media (max-width: 1280px) {
        font-size: $font-size-text5;
      }

      @media (max-width: 1024px) {
        font-size: $font-size-text4;
      }

      @media (max-width: 880px) {
        font-size: $font-size-text3;
      }
    }

    .panel-centered-actions {
      margin-top: 25px;
      text-align: center;

      .btn-primary {
        margin-right: 5px;
      }
    }

    .text-emphasise {
      display: block;
      font-size: $font-size-text8;

      @media (max-width: 1280px) {
        font-size: $font-size-text7;
      }

      @media (max-width: 1024px) {
        font-size: $font-size-text6;
      }

      @media (max-width: 880px) {
        font-size: $font-size-text4;
      }
    }

    .confirmation-msg {
      display: none;
      padding: 16px 22px 27px 22px;
      font-size: $font-size-text8;

      @media (max-width: 1280px) {
        font-size: $font-size-text7;
      }

      @media (max-width: 1024px) {
        font-size: $font-size-text6;
      }

      @media (max-width: 880px) {
        font-size: $font-size-text4;
      }
    }

    .btn {
      margin-top: 6px;
    }
  }

}
